import React, { useState, useEffect } from 'react';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';
import Autocomplete, { createFilterOptions } from '@mui/material/Autocomplete';
import PropTypes from 'prop-types';
import { useFormContext, Controller } from 'react-hook-form';

const filter = createFilterOptions();

function RHFAutocompleteCombo({ onChange, name, label, helperText, selection, dialogTitle, dialogContent, onDialogChange, size, margin, defaultValue, disabled }) {

    const { control, setValue } = useFormContext();
    const [autocompleteValue, setAutocompleteValue] = useState(defaultValue || null); // Set the default value
    const [open, toggleOpen] = useState(false);
    const [dialogValue, setDialogValue] = useState({
        title: '',
        value: '',
    });

    useEffect(() => {
        // Set the default value when defaultValue prop changes
        setAutocompleteValue(defaultValue || null);
    }, [defaultValue]);

    const handleClose = () => {
        setDialogValue({
            title: '',
            value: '',
        });
        toggleOpen(false);
    };

    const handleSubmit = (event) => {
        event.preventDefault();
        setValue(name, {
            title: dialogValue.title,
            // value: parseInt(dialogValue.value, 10),
        });
        setDialogValue({
            title: dialogValue.title,
            value: '',
        });
        onDialogChange(dialogValue);

        onChange(event, dialogValue)
        handleClose();
    };

    return (
        <>
            <Controller
                name={name}
                control={control}
                render={({ field, fieldState: { error } }) => (
                    <Autocomplete
                        {...field}
                        value={autocompleteValue}
                        disabled={disabled} 
                        onChange={(event, newValue) => {
                            if (onChange) {
                                onChange(event, newValue); // Execute the provided onChange handler
                            }

                            if (typeof newValue === 'string') {
                                setTimeout(() => {
                                    toggleOpen(true);
                                    setDialogValue({
                                        title: newValue,
                                        value: '',
                                    });
                                });
                            } else if (newValue && newValue.inputValue) {
                                toggleOpen(true);
                                setDialogValue({
                                    title: newValue.inputValue,
                                    value: '',
                                });
                            } else {
                                setAutocompleteValue(newValue);
                            }
                        }}
                        filterOptions={(options, params) => {
                            const filtered = filter(options, params);

                            if (params.inputValue !== '') {
                                filtered.push({
                                    inputValue: params.inputValue,
                                    title: `Add "${params.inputValue}"`,
                                });
                            }

                            return filtered;
                        }}
                        options={selection}
                        getOptionLabel={(option) => {
                            if (typeof option === 'string') {
                                return option;
                            }
                            if (option.inputValue) {
                                return option.inputValue;
                            }
                            return option.title;
                        }}
                        selectOnFocus
                        clearOnBlur
                        handleHomeEndKeys
                        renderOption={(props, option) => <li {...props} style={{ fontSize: 'inherit', lineHeight: 'inherit' }}>
                            {option.title}
                        </li>}
                        sx={{ width: 300 }}
                        freeSolo
                        renderInput={(params) => (
                            <TextField
                                label={label}
                                error={!!error}
                                helperText={error ? error?.message : helperText}
                                {...params}
                            />
                        )}
                    />
                )}
            />

            <Dialog open={open} onClose={handleClose}>
                {/* <form onSubmit={handleSubmit}> */}
                <DialogTitle>{dialogTitle}</DialogTitle>
                <DialogContent>
                    <DialogContentText>{dialogContent}</DialogContentText>
                    <TextField
                        autoFocus
                        margin={margin || "dense"}
                        id="name"
                        value={dialogValue.title}
                        onChange={(event) => {
                            setDialogValue({
                                ...dialogValue,
                                title: event.target.value,
                            });
                        }}
                        label="Role Name"
                        type="text"
                        variant="standard"
                        size={size || "medium"}
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose}>Cancel</Button>
                    <Button onClick={handleSubmit}>Add</Button>
                </DialogActions>
                {/* </form> */}
            </Dialog>

        </>
    );
}

RHFAutocompleteCombo.propTypes = {
    name: PropTypes.string,
    onChange: PropTypes.func,
    label: PropTypes.string,
    helperText: PropTypes.node,
    selection: PropTypes.array,
    dialogTitle: PropTypes.string,
    dialogContent: PropTypes.string,
    onDialogChange: PropTypes.func,
    size: PropTypes.string,
    margin: PropTypes.string,
    defaultValue: PropTypes.string,
    disabled: PropTypes.bool,
};
export default RHFAutocompleteCombo;