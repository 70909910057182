import { createSlice } from '@reduxjs/toolkit';
import axios from 'axios';
// utils
import { ServerConfiguration,ProjectID } from '../../utils/serverConfig';

// ----------------------------------------------------------------------

const url = ServerConfiguration.testingServerUrl;
// const PROJECTID = 1;

const initialState = {
  isLoading: false,
  error: null,
  users: [],
  userByID: [],
  inviteeRole: [],
  editorResponse: [],
  usersDeleted: [],
  userManagement: null,
  roleTypes: [],
  openModal: false,
  selectedUserId: null,
  selectedRange: null,
  countries: [],
  expertises: [],
  password: null,
  userUpdate: [],
  userPage: [],
  editorList: [],
  notificationList: [],
  notificationAction: [],
  invitationCheck: [],
  pricing: [],
  userIPAddress: null,
  userLocation: null,
  token: null
};

const slice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    // GET PRODUCT
    getProductSuccess(state, action) {
      state.isLoading = false;
      state.userManagement = action.payload;
    },

    // GET USER ROLE TYPES
    getRoleTypeSuccess(state, action) {
      state.isLoading = false;
      state.roleTypes = action.payload;
    },

    // GET USERS
    getUsersSuccess(state, action) {
      state.isLoading = false;
      state.users = action.payload;
    },

    getUserPageViewSuccess(state, action) {
      state.isLoading = false;
      state.userPage = action.payload;
    },


    // GET USER
    getUserSuccess(state, action) {
      state.isLoading = false;
      state.userByID = action.payload;
    },

    // CREATE USER
    createUserSuccess(state, action) {
      const newUser = action.payload;
      state.isLoading = false;
      state.users = [...state.users, newUser];
    },

    // UPDATE USER
    updateUserSuccess(state, action) {
      state.isLoading = false;
      state.users = state.users.map((user) => {
        if (user.id === action.payload.id) {
          return action.payload;
        }
        return user;
      });
    },

    // USER RESPONSE
    userResponseSuccess(state, action) {
      state.isLoading = false;
      state.editorResponse = action.payload;
    },

    // DELETE USER
    deleteUserSuccess(state, action) {
      // const UserId = action.payload;
      // state.users = state.users.filter((user) => user.id !== UserId);
      state.isLoading = false;
      state.usersDeleted = action.payload;
    },

    // SELECT USER
    selectUser(state, action) {
      const UserId = action.payload;
      state.openModal = true;
      state.selectedUserId = UserId;
    },

    // SELECT RANGE
    selectRange(state, action) {
      const { start, end } = action.payload;
      state.openModal = true;
      state.selectedRange = { start, end };
    },

    // OPEN MODAL
    onOpenModal(state) {
      state.openModal = true;
    },

    // CLOSE MODAL
    onCloseModal(state) {
      state.openModal = false;
      state.selectedUserId = null;
      state.selectedRange = null;
    },

    // GET COUNTRIES
    getCountriesSuccess(state, action) {
      state.isLoading = false;
      state.countries = action.payload;
    },

    // GET EXPERTISES
    getExpertisesSuccess(state, action) {
      state.isLoading = false;
      state.expertises = action.payload;
    },

    // UPDATE INVITEE ROLE
    updateInviteeRoleSuccess(state, action) {
      state.isLoading = false;
      state.inviteeRole = action.payload;
    },

    // UPDATE PASSWORD
    updatePasswordSuccess(state, action) {
      state.isLoading = false;
      state.password = action.payload;
    },

    // UNSUBSCRIBE UPDATE PASSWORD
    unsubscribeUpdatePasswordSuccess(state, action) {
      state.isLoading = false;
      state.password = action.payload;
    },

    // UNSUBSCRIBE USER PAGE
    unsubscribeUserPage(state, action) {
      state.isLoading = false;
      state.userPage = [];
    },

    // EDITOR LISTING TO INVITE AND INVITED
    getEditorListSuccess(state, action) {
      state.isLoading = false;
      state.editorList = action.payload;
    },

    // USER NOTIFICATION LISTING
    getNotificationListSuccess(state, action) {
      state.isLoading = false;
      state.notificationList = action.payload;
    },

    // USER NOTIFICATION LISTING
    updateNotificationStatusSuccess(state, action) {
      state.isLoading = false;
      state.notificationAction = action.payload;
    },

    // CHECK USER INVITATION
    invitationCheckSuccess(state, action) {
      state.isLoading = false;
      state.invitationCheck = action.payload;
    },

    getPricingSuccess(state, action) {
      state.isLoading = false;
      state.pricing = action.payload;
    },

    requestIPAddressSuccess(state, action) {
      state.isLoading = false;
      state.userIPAddress = action.payload;
    },

    requestLocationPermissionSuccess(state, action) {
      state.isLoading = false;
      state.userLocation = action.payload;
    },

   

  },
});

// Reducer
export default slice.reducer;

// Actions
export const { onOpenModal, onCloseModal, selectUser, selectRange,  } = slice.actions;

// ---------------------------------------------------------------------
export function getUserPageView(userId) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await fetch(`${url}User_Page_View?USERID=${userId}&PROJECTID=${ProjectID}`);
      const json = await response.json();
      const data = JSON.parse(json)[0];
      if (data && data.ReturnVal === 1) {
        dispatch(slice.actions.getUserPageViewSuccess(JSON.parse(data.ReturnData)));
      } else {
        dispatch(slice.actions.hasError(JSON.parse(data.ReturnData)[0].ReturnSqlError));
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getUsers(userId, journalId) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await fetch(`${url}Paper_AuthorPaper_PaperListByUserID?USERID=${userId}&PROJECTID=${ProjectID}&JOURNALID=${journalId}`);
      const json = await response.json();
      const data = JSON.parse(json);
      dispatch(slice.actions.getUsersSuccess(data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getUserByType(userTypeId) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await fetch(`${url}User_Profile_ViewByUserType?USERTYPEID=${userTypeId}&PROJECTID=${ProjectID}`);
      const json = await response.json();
      const data = JSON.parse(json)[0];
      if (data && data.ReturnVal === 1) {
        dispatch(slice.actions.getUsersSuccess(JSON.parse(data.ReturnData)));
      } else {
        dispatch(slice.actions.hasError(JSON.parse(data.ReturnData)[0].ReturnSqlError));
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function getUser(userId, USEREXPERTEDIND, USERDASHBOARDIND) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await fetch(`${url}User_Profile_ViewByUserID?USERID=${userId}&PROJECTID=${ProjectID}&USEREXPERTEDIND=${USEREXPERTEDIND}&USERDASHBOARDIND=${USERDASHBOARDIND}`);
      const json = await response.json();
      const data = JSON.parse(json);
      if (data && data[0].ReturnVal === 1) {
        dispatch(slice.actions.getUserSuccess(JSON.parse(data[0].ReturnData)));
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function createUser(userId, values, profilePic, profilePicFileType) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    const isHalfDay = values.option !== 0 ? 1 : 0;
    try {
      const response = await fetch(
        `${url}Employee_AddEmployeeInfo?LogOnUserID=${userId}&StaffName=${values.name}&StaffEmail=${values.email}&StaffPhone=${values.phoneNumber}
          &StatusID=${values.statusType}&RecruitmentDate=${values.recruitmentDate}&RoleID=${values.role}&StaffAddress=${values.address}
          &StaffGender=${values.gender} &ICorPassportNo=${values.ICNo}&StaffDateOfBirth=${values.staffDOB}&StaffUsername=${values.username}
          &StaffPassword=${values.userPassword}&ProfilePictureName=${profilePic}&MediaType=${profilePicFileType}`
      )
      const json = await response.json();
      const data = JSON.parse(json);
      dispatch(slice.actions.createUserSuccess(data));
      // dispatch(slice.actions.resetLeaveSubmitSuccess(data));

    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function updateUser(user) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await fetch(
        `${url}User_Profile_Update?USERID=${user.userId}&USEREMAIL=${user.email}&PROJECTID=${ProjectID}&PASSWORD=${user.password}&USERCONTACTNO=${user.contactNo}&USERDOB=${user.dob}&USERORCHIDID=${user.orchidId}&USERPREFIX=${user.prefix}&USERFIRSTNAME=${user.firstName}&USERLASTNAME=${user.lastName}&USERINSTITUTION=${user.institution}&USERDEGREE=${user.degree}&USERADDRESS=${user.address}&USERSTATE=${user.nationState}&USERCOUNTRYID=${user.country}&USERCITY=${user.city}&USERPOSTALCODE=${user.zipCode}&USERRESEARCHID=${user.researchId}&USERGENDER=${user.gender}&USERTYPEID=${user.userType}&USEREXPERTISE=${user.expertise}`
      )
      const json = await response.json();
      const data = JSON.parse(json);
      if (data && data.ReturnVal === 1) {
        dispatch(slice.actions.updateUserSuccess(JSON.parse(data.ReturnData)[0]));
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function userResponse(email, acceptInd) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await fetch(
        `${url}User_RespondAsEditor?USEREMAIL=${email}&RESPOND=${acceptInd}&PROJECTID=${ProjectID}`
      )
      const json = await response.json();
      const data = JSON.parse(json)[0];
      if (data && data.ReturnVal === 1) {
        dispatch(slice.actions.userResponseSuccess(JSON.parse(data.ReturnData)));
      } else {
        dispatch(slice.actions.hasError(JSON.parse(data.ReturnData)[0].ReturnSqlError));
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function userCheckInvitation(email, invitationType, paperid) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await fetch(
        `${url}User_CheckInvitation?USEREMAIL=${email}&INVITATIONTYPE=${invitationType}&PAPERID=${paperid}`
      )
      const json = await response.json();
      const data = JSON.parse(json)[0];
      if (data && data.ReturnVal === 1) {
        dispatch(slice.actions.invitationCheckSuccess(JSON.parse(data.ReturnData)));
      } else {
        dispatch(slice.actions.hasError(JSON.parse(data.ReturnData)[0].ReturnSqlError));
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function deleteUsers(userId, modifierId) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading())
    try {
      const response = await fetch(`${url}User_Profile_Delete?USERID=${userId}&MODIFYBY=${modifierId}`);
      const json = await response.json();
      const data = JSON.parse(json);
      if (data && data.ReturnVal === 1) {
        dispatch(slice.actions.deleteUserSuccess(JSON.parse(data.ReturnData)[0]));
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function getRoleType() {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading())
    try {
      const response = await fetch(
        `${url}Role_ViewRoleType`
      )
      const json = await response.json();
      const data = JSON.parse(json);
      dispatch(slice.actions.getRoleTypeSuccess(data));

    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function getCountries() {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await fetch(`${url}General_ViewCountry`);
      const json = await response.json();
      const data = JSON.parse(json)[0];
      if (data && data.ReturnVal === 1) {
        dispatch(slice.actions.getCountriesSuccess(JSON.parse(data.ReturnData)));
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function getPricing() {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await fetch(`${url}General_ViewPricing?PRICINGTYPE=${ProjectID}`);
      const json = await response.json();
      const data = JSON.parse(json)[0];
      if (data && data.ReturnVal === 1) {
        dispatch(slice.actions.getPricingSuccess(JSON.parse(data.ReturnData)));
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function getExpertises() {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await fetch(`${url}General_ViewExpertise`);
      const json = await response.json();
      const data = JSON.parse(json)[0];
      if (data && data.ReturnVal === 1) {
        dispatch(slice.actions.getExpertisesSuccess(JSON.parse(data.ReturnData)));
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function getEditorInviteList() {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await fetch(`${url}User_Profile_EditorListToInvite`);
      const json = await response.json();
      const data = JSON.parse(json)[0];
      if (data && data.ReturnVal === 1) {
        dispatch(slice.actions.getEditorListSuccess(JSON.parse(data.ReturnData)));
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getUserNotificationList(useremail) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await fetch(`${url}Notification_ViewByUserEmail?PROJECTID=${ProjectID}&USEREMAIL=${useremail}`);
      const json = await response.json();
      const data = JSON.parse(json)[0];

      if (data && data.ReturnVal === 1) {
        dispatch(slice.actions.getNotificationListSuccess(JSON.parse(data.ReturnData)));
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function updateUserNotificationStatus(notificationId, isRead, modifyby) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await fetch(`${url}Notification_UpdateStatus?PROJECTID=${ProjectID}&NOTIFICATIONID=${notificationId}&ISREAD=${isRead}&MODIFYBY=${modifyby}`);
      const json = await response.json();
      const data = JSON.parse(json)[0];

      if (data && data.ReturnVal === 1) {
        dispatch(slice.actions.updateNotificationStatusSuccess(JSON.parse(data.ReturnData)));
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function updateInviteeRole(email, modifierId, userTypeId, emailId) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await fetch(`${url}User_ProfileUserType_Update?USEREMAIL=${email}&MODIFYBY=${modifierId}&USERTYPEID=${userTypeId}&PROJECTID=${ProjectID}&INVITATIONEMAILID=${emailId}`);
      const json = await response.json();
      const data = JSON.parse(json);
      if (data && data.ReturnVal === 1) {
        dispatch(slice.actions.updateInviteeRoleSuccess(JSON.parse(data.ReturnData)[0]));
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function updatePassword(passwords, userId) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await fetch(
        `${url}User_Profile_Update_Password?USERID=${userId}&OLDPASSWORD=${passwords.oldPassword}&NEWPASSWORD=${passwords.newPassword}`
      );
      const json = await response.json();
      const data = JSON.parse(json)[0];
      if (data && data.ReturnVal === 1) {
        dispatch(slice.actions.updatePasswordSuccess(JSON.parse(data.ReturnData)[0]));
      } else {
        dispatch(slice.actions.hasError(JSON.parse(data.ReturnData)[0].ReturnSqlError));
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function requestIPAddress() {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      axios.get('https://api.ipify.org?format=json')
      .then(response => {
        dispatch(slice.actions.requestIPAddressSuccess(response.data.ip));
      })
      .catch(error => {
        dispatch(slice.actions.hasError(`Error fetching IP address: ${error}`));
      });

    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}




export function requestLocationPermission() {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      if ("geolocation" in navigator) {
        navigator.geolocation.getCurrentPosition(
          (position) => {
            const obj = {
              longitude: position.coords.longitude.toFixed(10),
              latitude: position.coords.latitude.toFixed(10)
            }
            dispatch(slice.actions.requestLocationPermissionSuccess(obj));
          },
          (error) => {
            dispatch(slice.actions.hasError(`Error getting location: ${error.message}`));
            // Handle error or denied permission
          }
        );
      } else {
        dispatch(slice.actions.hasError("Geolocation is not supported by your browser"));
        // Geolocation not supported by the browser
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function unsubscribeUpdatePassword() {
  return async (dispatch) => {
    dispatch(slice.actions.unsubscribeUpdatePasswordSuccess());
  };
}

export function unsubscribeUserPage() {
  return async (dispatch) => {
    dispatch(slice.actions.unsubscribeUserPage());
  };
}