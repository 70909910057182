// ----------------------------------------------------------------------

export const role = [
  'Sarawak Research Society & UCSI University, Malaysia',
  'Universitas Asa Indonesia, Indonesia',
  'Zhejiang University',
  'Naval Postgraduate School, USA',
  'City University of Hong Kong',
  'Victoria University of Wellington, New Zealand',
  'Carleton University, Canada',
  'University of Illinois, USA',
  'project manager',
  'ui designer',
  'ui/ux designer',
  'ui/ux designer',
  'ui designer',
  'backend developer',
  'backend developer',
  'front end developer',
  'backend developer',
  'full stack designer',
  'full stack developer',
  'backend developer',
  'ux designer',
  'ui designer',
  'project manager',
  'ui/ux designer',
  'ui designer',
  'project manager',
  'full stack developer',
  'hr manager',
  'hr manager',
  'ui/ux designer',
  'project manager',
  'full stack designer',
  'ui designer',
  'leader',
  'front end developer',
  'ui/ux designer',
  'project manager',
  'ui/ux designer',
  'ui designer',
  'full stack designer',
];
