import PropTypes from 'prop-types';
// form
import { useFormContext, Controller } from 'react-hook-form';
// @mui
import { ToggleButtonGroup, ToggleButton, FormHelperText } from '@mui/material';

// ----------------------------------------------------------------------

RHFButtonGroup.propTypes = {
  name: PropTypes.string,
  helperText: PropTypes.node,
  options: PropTypes.array,
  disabled: PropTypes.bool,
};

export default function RHFButtonGroup({ name, helperText, options,disabled,...other }) {
  const { control, setValue  } = useFormContext();

  return (
    <Controller
      name={name}
      control={control}
      render={({ field, fieldState: { error } }) => (
        <div>

          <ToggleButtonGroup
            fullWidth            
            disabled={disabled}
            onChange={(event, value) => {
              console.log("dsadasdasdas", value)
              setValue(name, value); // Update form value
              field.onChange(value); // Trigger React Hook Form validation
            }}
            value={field.value}
            {...field}
          >
            {options.map((option) => (
              <ToggleButton key={option} value={option}>
                {option}
              </ToggleButton>
            ))}
          </ToggleButtonGroup>

          {(!!error || helperText) && (
            <FormHelperText error={!!error}>{error ? error?.message : helperText}</FormHelperText>
          )}
        </div>
      )}
    />
  );
}
