import { combineReducers } from 'redux';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
// slices
import mailReducer from './slices/mail';
// import chatReducer from './slices/chat';
// import productReducer from './slices/product';
// import calendarReducer from './slices/calendar';
// import kanbanReducer from './slices/kanban';
import conferenceEventReducer from './slices/conference-event';
import conferencePaperReducer from './slices/conference-paper';
// import leaveApplicationReducer from './slices/leaveApplication';
import billingReducer from './slices/billing';
import userReducer from './slices/user';
import manuscriptReducer from './slices/manuscript';
import profileReducer from './slices/profile';
import journalReducer from './slices/journal';
import taskReducer from './slices/task';
import questionReducer from './slices/question';
import authorReducer from './slices/author';
import surveyReducer from './slices/survey';
import formReducer from './slices/form';
import publisherReducer from './slices/publisher';
import participantReducer from './slices/participant';

// ----------------------------------------------------------------------

export const rootPersistConfig = {
  key: 'root',
  storage,
  keyPrefix: 'redux-',
  whitelist: [],
};

export const productPersistConfig = {
  key: 'product',
  storage,
  keyPrefix: 'redux-',
  whitelist: ['sortBy', 'checkout'],
};

export const journalPersistConfig = {
  key: 'journal',
  storage,
  keyPrefix: 'redux-',
  whitelist: [''],
};

export const authorPersistConfig = {
  key: 'author',
  storage,
  keyPrefix: 'redux-',
  whitelist: [''],
};

const rootReducer = combineReducers({
  mail: mailReducer,
  participant: participantReducer,
  user: userReducer,
  manuscript: manuscriptReducer,
  conferenceEvent: conferenceEventReducer,
  billing: billingReducer,
  conferencePaper: conferencePaperReducer,
  profile: profileReducer,
  journal: persistReducer(journalPersistConfig, journalReducer),
  task: taskReducer,
  question: questionReducer,
  author: persistReducer(authorPersistConfig, authorReducer),
  survey: surveyReducer,
  form: formReducer,
  publisher: publisherReducer,
});

export default rootReducer;
