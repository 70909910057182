import axios from 'axios';
import { ServerConfiguration } from './serverConfig';

const uploader = async (files, filenames, directory, ID, onProgress) => {
  const formData = new FormData();
  formData.append("Directory", directory);
  formData.append("ID", ID);

  files.forEach((file, index) => {
    formData.append("upload[]", files[index]);
    formData.append("fileName[]", filenames[index]);
  });

  // const config = {
  //   headers: { 'Content-Type': 'multipart/form-data' },
  //   onUploadProgress: progressEvent => {
  //     const progress = Math.round((progressEvent.loaded * 100) / progressEvent.total);
  //     onProgress(progress); // Update progress through callback
  //   }
  // };

  let config = { headers: { 'Content-Type': 'multipart/form-data' } };

  if (onProgress) {
    config = {
      ...config,
      onUploadProgress: progressEvent => {
        const progress = Math.round((progressEvent.loaded * 100) / progressEvent.total);
        onProgress(progress); // Update progress through callback
      }
    };
  }  
  
  const resp = await axios.post(ServerConfiguration.uploadUrl, formData, config)
  try {
    return resp.status === 200
  }
  catch (err) {
    console.log(err)
    return false
  }
}

export default uploader;