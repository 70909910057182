
import { createSlice } from '@reduxjs/toolkit';
// utils
import uploader from '../../utils/imgUploader';
import { ServerConfiguration, ProjectID } from '../../utils/serverConfig';

// ----------------------------------------------------------------------
const url = ServerConfiguration.testingServerUrl;

const initialState = {
  isLoading: false,
  error: null,
  conferenceEvents: [],
  // conference Action
  conferenceAction: [],
  conferenceRole: [],
  conferenceCertImage: [],
  conferenceActionData: [],
  conferenceDetailsActionData: [],
  // conference Detail Action
  conferenceDetailAction_Image: [],
  conferenceDetailAction_Speaker: [],
  conferenceDetailAction_Paper: [],
  conferenceDetailAction_Package: [],
  conferenceDetailAction_Payment: [],
  // View Data
  conference: [],
  conferenceImage: [],
  conferencePackage: [],
  // Conference Detail
  conferenceDetails: null,
  conferenceStatus: [],
  conferenceStatusAction: [],
  // user access
  conferenceAssistant: [],
  addedAssistant: [],
  removedAssistant: [],
  // 
  updatedConferenceActivation: null,
  //
  userAcceptedInvitation: null,
  conferenceOrder: null,

  conferenceTopics: [],
  checkedPackages: null,

  acceptAction: null,
  paynetConferenceOrder: null,
  orderStatus: null,
  orderDetails: null
};

const slice = createSlice({
  name: 'conferenceEvent',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    // GET CONFERENCE EVENTS 
    getConferenceEventsSuccess(state, action) {
      state.isLoading = false;
      state.conferenceEvents = action.payload;
    },

    getConferenceRoleSuccess(state, action) {
      state.isLoading = false;
      state.conferenceRole = action.payload;
    },

    getConferenceCertImageSuccess(state, action) {
      state.isLoading = false;
      state.conferenceCertImage = action.payload;
    },

    getConferenceEndorsedSuccess(state, action) {
      state.isLoading = false;
      state.conference = action.payload;
    },

    getConferenceSuccess(state, action) {
      state.isLoading = false;
      state.conference = action.payload;
    },

    getConferenceImageSuccess(state, action) {
      state.isLoading = false;
      state.conferenceImage = action.payload;
    },

    getConferencePackageSuccess(state, action) {
      state.isLoading = false;
      state.conferencePackage = action.payload;
    },

    deleteConferenceSuccess(state, action) {
      state.isLoading = false;
      state.conferenceAction = action.payload;
    },

    updateConferenceSuccess(state, action) {
      state.isLoading = false;
      state.conferenceActionData = action.payload;
    },

    submitConferenceStatusSuccess(state, action) {
      state.isLoading = false;
      state.conferenceStatusAction = action.payload;
    },

    updateConferenceDetailsSuccess(state, action) {
      state.isLoading = false;
      state.conferenceDetailsActionData = action.payload;
    },

    clearConferenceDataSuccess(state, action) {
      state.isLoading = false;
      state.conferenceActionData = [];
      state.conference = [];
      state.conferenceDetailsActionData = [];
      state.conferenceStatusAction = [];
    },

    getConferenceDetailsSuccess(state, action) {
      state.isLoading = false;
      state.conferenceDetails = action.payload;
    },

    getConferenceMembershipOrderSuccess(state, action) {
      state.isLoading = false;
      state.conferenceOrder = action.payload;
    },

    addConferenceMembershipPaynetOrderSuccess(state, action) {
      state.isLoading = false;
      state.paynetConferenceOrder = action.payload;
    },

    updateOrderPaymentStatusSuccess(state, action) {
      state.isLoading = false;
      state.orderStatus = action.payload;
    },

    clearTransactionData(state, action) {
      state.isLoading = false;
      state.orderStatus = null;
    },
    
    getOrderDetailByUUIDSuccess(state, action) {
      state.isLoading = false;
      state.orderDetails = action.payload;
    },

    clearConferenceData(state, action) {
      state.isLoading = false;
      state.conferenceOrder = null;
    },

    getConferenceStatusSuccess(state, action) {
      state.isLoading = false;
      state.conferenceStatus = action.payload;
    },

    unsubscribeDeleteConference(state, action) {
      state.isLoading = false;
      state.conferenceAction = [];
    },

    getConferenceAssistantSuccess(state, action) {
      state.isLoading = false;
      state.conferenceAssistant = action.payload;
    },

    addConferenceAssistantSuccess(state, action) {
      state.isLoading = false;
      state.addedAssistant = action.payload;
    },

    removeConferenceAssistantSuccess(state, action) {
      state.isLoading = false;
      state.removedAssistant = action.payload;
    },

    updateConferenceActivationSuccess(state, action) {
      state.isLoading = false;
      state.updatedConferenceActivation = action.payload;
    },

    unsubscribeUpdateConferenceActivation(state, action) {
      state.isLoading = false;
      state.updatedConferenceActivation = null;
    },



    userAcceptedInvitationSuccess(state, action) {
      state.isLoading = false;
      state.userAcceptedInvitation = action.payload;
    },


    getConferenceTopicSuccess(state, action) {
      state.isLoading = false;
      state.conferenceTopics = action.payload;
    },

    getPackageValiditySuccess(state, action) {
      state.isLoading = false;
      state.checkedPackages = action.payload;
    },

    userAcceptActionSuccess(state, action) {
      state.isLoading = false;
      state.acceptAction = action.payload;
    },

    clearUserAcceptActionSuccess(state, action) {
      state.isLoading = false;
      state.acceptAction = null
    },

    clearPackageValiditySuccess(state, action) {
      state.isLoading = false;
      state.checkedPackages = null;
    },
  },
});

// Reducer
export default slice.reducer;

// Actions
export const {
  getConferenceEventsSuccess,
  unsubscribeDeleteConference,
  clearTransactionData
} = slice.actions;

// ----------------------------------------------------------------------

export function fetchConferenceEvents() {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await fetch(
        `${url}Paper_ReviewerUpdateReview`
      )
      const json = await response.json();
      const data = JSON.parse(json)[0];
      if (data && data.ReturnVal === 1) {
        dispatch(slice.actions.sendReviewSuccess(JSON.parse(data.ReturnData)));
      } else {
        dispatch(slice.actions.hasError(JSON.parse(data.ReturnData)[0].ReturnSqlError));
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function viewConferenceRole() {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await fetch(
        `${url}Conference_ViewConferenceRole`
      )
      const json = await response.json();
      const data = JSON.parse(json)[0];
      if (data && data.ReturnVal === 1) {
        dispatch(slice.actions.getConferenceRoleSuccess(JSON.parse(data.ReturnData)));
      } else {
        dispatch(slice.actions.hasError(JSON.parse(data.ReturnData)[0].ReturnSqlError));
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function viewConferenceCertImage() {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await fetch(
        `${url}Conference_ViewConferenceCertFrameImage`
      )
      const json = await response.json();
      const data = JSON.parse(json)[0];
      if (data && data.ReturnVal === 1) {
        dispatch(slice.actions.getConferenceCertImageSuccess(JSON.parse(data.ReturnData)));
      } else {
        dispatch(slice.actions.hasError(JSON.parse(data.ReturnData)[0].ReturnSqlError));
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------
export function viewEndorsedConference(showAvailableInd, packageInd, ImgInd, paperInd, paymentInd, speakerInd, participantInd, dashboardInd) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());

    try {
      const response = await fetch(
        `${url}Conference_ViewEndorsedConference?REQUIREDFILTERDATE=${showAvailableInd}&CONFERENCEPACKAGEIND=${packageInd}&CONFERENCEIMAGEIND=${ImgInd}&CONFERENCEPAPERIND=${paperInd}&CONFERENCEPAYMENTINFOIND=${paymentInd}&CONFERENCESPEAKERIND=${speakerInd}&CONFERENCEPARTICIPANTIND=${participantInd}&CONFERENCEDASHBOARDIND=${dashboardInd}`
      )
      const json = await response.json();
      const data = JSON.parse(json)[0];
      if (data && data.ReturnVal === 1) {
        dispatch(slice.actions.getConferenceEndorsedSuccess(JSON.parse(data.ReturnData)));
      } else {
        dispatch(slice.actions.hasError(JSON.parse(data.ReturnData)[0].ReturnSqlError));
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}


export function viewHistoryConference(userId) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());

    try {
      const response = await fetch(
        `${url}Conference_ViewConferenceHistoryByUserID?USERID=${userId}`
      )
      const json = await response.json();
      const data = JSON.parse(json)[0];
      if (data && data.ReturnVal === 1) {
        dispatch(slice.actions.getConferenceSuccess(JSON.parse(data.ReturnData)));
      } else {
        dispatch(slice.actions.hasError(JSON.parse(data.ReturnData)[0].ReturnSqlError));
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function viewConference(id, userId, isAdmin, packageInd, ImgInd, paperInd, paymentInd, speakerInd, participantInd, dashboardInd, CONFERENCEPARTNERSHIPIND) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());

    try {
      const response = await fetch(
        `${url}Conference_ViewConference?CONFERENCEID=${id}&USERID=${userId}&ISADMIN=${isAdmin}&CONFERENCEPACKAGEIND=${packageInd}&CONFERENCEIMAGEIND=${ImgInd}&CONFERENCEPAPERIND=${paperInd}&CONFERENCEPAYMENTINFOIND=${paymentInd}&CONFERENCESPEAKERIND=${speakerInd}&CONFERENCEPARTICIPANTIND=${participantInd}&CONFERENCEDASHBOARDIND=${dashboardInd}&CONFERENCEPARTNERSHIPIND=${CONFERENCEPARTNERSHIPIND}`
      )
      const json = await response.json();
      const data = JSON.parse(json)[0];
      if (data && data.ReturnVal === 1) {
        dispatch(slice.actions.getConferenceSuccess(JSON.parse(data.ReturnData)));
      } else {
        dispatch(slice.actions.hasError(JSON.parse(data.ReturnData)[0].ReturnSqlError));
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function viewConference_Image(id) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());

    try {
      const response = await fetch(
        `${url}Conference_ViewConferenceImage?CONFERENCEID=${id}`
      )
      const json = await response.json();
      const data = JSON.parse(json)[0];
      if (data && data.ReturnVal === 1) {
        dispatch(slice.actions.getConferenceImageSuccess(JSON.parse(data.ReturnData)));
      } else {
        dispatch(slice.actions.hasError(JSON.parse(data.ReturnData)[0].ReturnSqlError));
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}


export function viewConference_Package(id) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());

    try {
      const response = await fetch(
        `${url}Conference_ViewConferencePackage?CONFERENCEID=${id}`
      )
      const json = await response.json();
      const data = JSON.parse(json)[0];
      if (data && data.ReturnVal === 1) {
        dispatch(slice.actions.getConferencePackageSuccess(JSON.parse(data.ReturnData)));
      } else {
        dispatch(slice.actions.hasError(JSON.parse(data.ReturnData)[0].ReturnSqlError));
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ------------------------------- STAGE 1 ---------------------------------------

// export function addConference(name, description, theme, obj, field, website, imgInd, images, createdBy) {
//   return async (dispatch) => {
//     dispatch(slice.actions.startLoading());

//     try {
//       const response = await fetch(
//         `${url}Conference_AddConference?CONFERENCENAME=${name}&CONFERENCEDESCRIPTION=${description}&CONFERENCETHEME=${theme}&CONFERENCEOBJECTIVE=${obj}&CONFERENCERELATEDFIELD=${field}&CONFERENCEWEBSITE=${website}&CONFERENCEIMAGEIND=${imgInd}&CONFERENCEFILEURL=${images.filenames}&CREATEDBY=${createdBy}`
//       )
//       const json = await response.json();
//       const data = JSON.parse(json)[0];
//       if (data && data.ReturnVal === 1) {
//         if (images.filenames[0] !== "") {
//           const resp = await uploader(images.files, images.filenames, 'conferenceImage', JSON.parse(data.ReturnData)[0].ConferenceID);
//           if (!resp) {
//             dispatch(slice.actions.hasError('Error uploading image'));
//             return;
//           }
//         }

//         dispatch(slice.actions.updateConferenceSuccess(JSON.parse(data.ReturnData)));
//       } else {
//         dispatch(slice.actions.hasError(JSON.parse(data.ReturnData)[0].ReturnSqlError));
//       }
//     } catch (error) {
//       dispatch(slice.actions.hasError(error));
//     }
//   };
// }


export function addConferenceWithPricing(id, createdBy) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());

    try {
      const response = await fetch(
        `${url}Conference_AddConferenceWithPricingID?CONFERENCEPRICINGID=${id}&CREATEDBY=${createdBy}`
      )
      const json = await response.json();
      const data = JSON.parse(json)[0];
      if (data && data.ReturnVal === 1) {
        dispatch(slice.actions.updateConferenceSuccess(JSON.parse(data.ReturnData)));
      } else {
        dispatch(slice.actions.hasError(JSON.parse(data.ReturnData)[0].ReturnSqlError));
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}


export function deleteConference_Image(id, modifyBy) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());

    try {
      const response = await fetch(
        `${url}Conference_DeleteConferenceImage?CONFERENCEIMAGEID=${id}&MODIFIEDBY=${modifyBy}`
      )
      const json = await response.json();
      const data = JSON.parse(json)[0];
      if (data && data.ReturnVal === 1) {
        dispatch(slice.actions.updateConferenceDetailsSuccess(JSON.parse(data.ReturnData)));
      } else {
        dispatch(slice.actions.hasError(JSON.parse(data.ReturnData)[0].ReturnSqlError));
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}


export function addConference_Image(id, images, createdBy) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());

    try {
      const response = await fetch(
        `${url}Conference_AddConferenceImage?CONFERENCEID=${id}&&CONFERENCEFILEURL=${images.filenames}&CREATEDBY=${createdBy}`
      )
      const json = await response.json();
      const data = JSON.parse(json)[0];
      if (data && data.ReturnVal === 1) {
        const resp = await uploader(images.files, images.filenames, 'conferenceImage', id);
        if (!resp) {
          dispatch(slice.actions.hasError('Error uploading image'));
          return;
        }

        dispatch(slice.actions.updateConferenceDetailsSuccess(JSON.parse(data.ReturnData)));
      } else {
        dispatch(slice.actions.hasError(JSON.parse(data.ReturnData)[0].ReturnSqlError));
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}


export function updateConferenceConfirmation(id, modifyBy) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());

    try {
      const response = await fetch(
        `${url}Conference_UpdateConferenceConfirmation?CONFERENCEID=${id}&MODIFIEDBY=${modifyBy}`
      )
      const json = await response.json();
      const data = JSON.parse(json)[0];
      if (data && data.ReturnVal === 1) {
        dispatch(slice.actions.submitConferenceStatusSuccess(JSON.parse(data.ReturnData)));
      } else {
        dispatch(slice.actions.hasError(JSON.parse(data.ReturnData)[0].ReturnSqlError));
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function updateConferenceLogo(id, logo, modifyBy) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());

    try {
      const response = await fetch(
        `${url}Conference_UpdateConferenceLogo?CONFERENCEID=${id}&CONFERENCELOGO=${logo}&MODIFIEDBY=${modifyBy}`
      )
      const json = await response.json();
      const data = JSON.parse(json)[0];
      if (data && data.ReturnVal === 1) {
        dispatch(slice.actions.updateConferenceSuccess(JSON.parse(data.ReturnData)));
      } else {
        dispatch(slice.actions.hasError(JSON.parse(data.ReturnData)[0].ReturnSqlError));
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function updateConference_Stage1(id, name, logo, description, theme, obj, field, website, modifyBy) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());

    try {
      const response = await fetch(
        `${url}Conference_UpdateConference_Stage1?CONFERENCEID=${id}&CONFERENCENAME=${name}&CONFERENCELOGO=${logo}&CONFERENCEDESCRIPTION=${description}&CONFERENCETHEME=${theme}&CONFERENCEOBJECTIVE=${obj}&CONFERENCERELATEDFIELD=${field}&CONFERENCEWEBSITE=${website}&MODIFIEDBY=${modifyBy}`
      )
      const json = await response.json();
      const data = JSON.parse(json)[0];
      if (data && data.ReturnVal === 1) {
        dispatch(slice.actions.updateConferenceSuccess(JSON.parse(data.ReturnData)));
      } else {
        dispatch(slice.actions.hasError(JSON.parse(data.ReturnData)[0].ReturnSqlError));
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}


// ------------------------------- STAGE 2 ---------------------------------------

export function updateConference_Stage2(id, beginDate, endDate, registerBeginDate, registerEndDate, location, info, virtual, type, modifyBy) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());

    try {
      const response = await fetch(
        `${url}Conference_UpdateConference_Stage2?CONFERENCEID=${id}&CONFERENCEBEGINDATE=${beginDate}&CONFERENCEENDDATE=${endDate}&CONFERENCEREGISTRATIONBEGINDATE=${registerBeginDate}&CONFERENCEREGISTRATIONENDDATE=${registerEndDate}&CONFERENCELOCATION=${location}&CONFERENCELOCATIONINFO=${info}&CONFERENCEVIRTUALLINK=${virtual}&CONFERENCETYPE=${type}&MODIFIEDBY=${modifyBy}`
      )
      const json = await response.json();
      const data = JSON.parse(json)[0];
      if (data && data.ReturnVal === 1) {
        dispatch(slice.actions.updateConferenceSuccess(JSON.parse(data.ReturnData)));
      } else {
        dispatch(slice.actions.hasError(JSON.parse(data.ReturnData)[0].ReturnSqlError));
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ------------------------------- STAGE 3 ---------------------------------------

export function updateConference_Stage3(id, name, email, contact, website, CONFERENCEORGANISERPIC, CONFERENCEORGANISERPICEMAIL, CONFERENCEORGANISERPICCONTACT, modifyBy) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());

    try {
      const encodedWebsite = encodeURIComponent(website);
      const response = await fetch(
        `${url}Conference_UpdateConference_Stage3?CONFERENCEID=${id}&CONFERENCEORGANISERNAME=${name}&CONFERENCEORGANISEREMAIL=${email}&CONFERENCEORGANISERCONTACT=${contact}&CONFERENCEORGANISERWEBSITE=${encodedWebsite}&CONFERENCEORGANISERPIC=${CONFERENCEORGANISERPIC}&CONFERENCEORGANISERPICEMAIL=${CONFERENCEORGANISERPICEMAIL}&CONFERENCEORGANISERPICCONTACT=${CONFERENCEORGANISERPICCONTACT}&MODIFIEDBY=${modifyBy}`
      )
      const json = await response.json();
      const data = JSON.parse(json)[0];
      if (data && data.ReturnVal === 1) {
        dispatch(slice.actions.updateConferenceSuccess(JSON.parse(data.ReturnData)));
      } else {
        dispatch(slice.actions.hasError(JSON.parse(data.ReturnData)[0].ReturnSqlError));
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}


// ---------------------------------- STAGE 4 ------------------------------------

export function updateConference_Stage4(id, speakerInd, speaker, speakerAffiliation, role, roleId, modifyBy) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());

    try {
      const response = await fetch(
        `${url}Conference_UpdateConference_Stage4?CONFERENCEID=${id}&CONFERENCESPEAKERIND=${speakerInd}&CONFERENCESPEAKER=${speaker}&CONFERENCESPEAKERAFFILIATION=${speakerAffiliation}&CONFERENCEROLE=${role}&CONFERENCEROLEID=${roleId}&MODIFIEDBY=${modifyBy}`
      )
      const json = await response.json();
      const data = JSON.parse(json)[0];
      if (data && data.ReturnVal === 1) {
        dispatch(slice.actions.updateConferenceSuccess(JSON.parse(data.ReturnData)));
      } else {
        dispatch(slice.actions.hasError(JSON.parse(data.ReturnData)[0].ReturnSqlError));
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function updateConference_Speaker(id, speakerId, speaker, speakerAffiliation, roleId, modifyBy) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());

    try {
      const response = await fetch(
        `${url}Conference_UpdateConferenceSpeaker?CONFERENCEID=${id}&CONFERENCESPEAKERID=${speakerId}&CONFERENCESPEAKER=${speaker}&CONFERENCESPEAKERAFFILIATION=${speakerAffiliation}&CONFERENCEROLEID=${roleId}&MODIFIEDBY=${modifyBy}`
      )
      const json = await response.json();
      const data = JSON.parse(json)[0];
      if (data && data.ReturnVal === 1) {
        dispatch(slice.actions.updateConferenceDetailsSuccess(JSON.parse(data.ReturnData)));
      } else {
        dispatch(slice.actions.hasError(JSON.parse(data.ReturnData)[0].ReturnSqlError));
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function deleteConference_Speaker(id, modifyBy) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());

    try {
      const response = await fetch(
        `${url}Conference_DeleteConferenceSpeaker?CONFERENCESPEAKERID=${id}&MODIFIEDBY=${modifyBy}`
      )
      const json = await response.json();
      const data = JSON.parse(json)[0];
      if (data && data.ReturnVal === 1) {
        dispatch(slice.actions.updateConferenceDetailsSuccess(JSON.parse(data.ReturnData)));
      } else {
        dispatch(slice.actions.hasError(JSON.parse(data.ReturnData)[0].ReturnSqlError));
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}


// --------------------------------- STAGE 5 -------------------------------------

export function updateConference_Stage5(id, submissionInd, paperName, topic, guideline, wordLimit, submissionDate, notificationDate, finalSubmissionDate, modifyBy) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());

    try {
      const response = await fetch(
        `${url}Conference_UpdateConference_Stage5?CONFERENCEID=${id}&CONFERENCEPAPERSUBMISSIONIND=${submissionInd}&CONFERENCEPAPER=${paperName}&CONFERENCETOPICNAME=${topic}&CONFERENCEPAPERGUIDELINE=${guideline}&CONFERENCEPAPERWORDLIMIT=${wordLimit}&CONFERENCEPAPERSUBMISSIONDATE=${submissionDate}&CONFERENCEPAPERNOTIFICATIONDATE=${notificationDate}&CONFERENCEPAPERFINALSUBMISSIONDATE=${finalSubmissionDate}&MODIFIEDBY=${modifyBy}`
      )
      const json = await response.json();

      const data = JSON.parse(json)[0];

      if (data && data.ReturnVal === 1) {
        dispatch(slice.actions.updateConferenceSuccess(JSON.parse(data.ReturnData)));
      } else {
        dispatch(slice.actions.hasError(JSON.parse(data.ReturnData)[0].ReturnSqlError));
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function updateConference_Paper(id, paperId, paperName, topic, guideline, wordLimit, submissionDate, notificationDate, finalSubmissionDate, modifyBy) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());

    try {
      const response = await fetch(
        `${url}Conference_UpdateConferencePaper?CONFERENCEID=${id}&CONFERENCEPAPERID=${paperId}&CONFERENCEPAPER=${paperName}&CONFERENCETOPICNAME=${topic}&CONFERENCEPAPERGUIDELINE=${guideline}&CONFERENCEPAPERWORDLIMIT=${wordLimit}&CONFERENCEPAPERSUBMISSIONDATE=${submissionDate}&CONFERENCEPAPERNOTIFICATIONDATE=${notificationDate}&CONFERENCEPAPERFINALSUBMISSIONDATE=${finalSubmissionDate}&MODIFIEDBY=${modifyBy}`
      )
      const json = await response.json();
      const data = JSON.parse(json)[0];
      if (data && data.ReturnVal === 1) {
        dispatch(slice.actions.updateConferenceDetailsSuccess(JSON.parse(data.ReturnData)));
      } else {
        dispatch(slice.actions.hasError(JSON.parse(data.ReturnData)[0].ReturnSqlError));
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function deleteConference_Paper(id, modifyBy) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());

    try {
      const response = await fetch(
        `${url}Conference_DeleteConferencePaper?CONFERENCEPAPERID=${id}&MODIFIEDBY=${modifyBy}`
      )
      const json = await response.json();
      const data = JSON.parse(json)[0];
      if (data && data.ReturnVal === 1) {
        dispatch(slice.actions.updateConferenceDetailsSuccess(JSON.parse(data.ReturnData)));
      } else {
        dispatch(slice.actions.hasError(JSON.parse(data.ReturnData)[0].ReturnSqlError));
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}


// ------------------------------- STAGE 6---------------------------------------


export function updateConference_Stage6(id, packageInd, packageName, price, startDate, endDate, paxLimit, curency, conferenceprice, convertCurrency, convertConferencePrice, modifyBy) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());

    try {
      const response = await fetch(
        `${url}Conference_UpdateConference_Stage6?CONFERENCEID=${id}&CONFERENCEPACKAGEIND=${packageInd}&CONFERENCEPACKAGENAME=${packageName}&CONFERENCEPRICE=${price}&CONFERENCEPACKAGESTARTDATE=${startDate}&CONFERENCEPACKAGEENDDATE=${endDate}&CONFERENCEPAXLIMIT=${paxLimit}&CURRENCY=${curency}&CONFERENCEPACKAGEPRICE=${conferenceprice}&CONVERTEDCURRENCY=${convertCurrency}&CONVERTEDCONFERENCEPACKAGEPRICE=${convertConferencePrice}&MODIFIEDBY=${modifyBy}`
      )
      const json = await response.json();
      const data = JSON.parse(json)[0];
      if (data && data.ReturnVal === 1) {
        dispatch(slice.actions.updateConferenceSuccess(JSON.parse(data.ReturnData)));
      } else {
        dispatch(slice.actions.hasError(JSON.parse(data.ReturnData)[0].ReturnSqlError));
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}


export function updateConference_Package(id, packageId, packageName, price, startDate, endDate, paxLimit, curency, conferenceprice, convertCurrency, convertConferencePrice, modifyBy) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());

    try {
      const response = await fetch(
        `${url}Conference_UpdateConferencePackage?CONFERENCEID=${id}&CONFERENCEPACKAGEID=${packageId}&CONFERENCEPACKAGENAME=${packageName}&CONFERENCEPRICE=${price}&CONFERENCEPACKAGESTARTDATE=${startDate}&CONFERENCEPACKAGEENDDATE=${endDate}&CONFERENCEPAXLIMIT=${paxLimit}&CURRENCY=${curency}&CONFERENCEPACKAGEPRICE=${conferenceprice}&CONVERTEDCURRENCY=${convertCurrency}&CONVERTEDCONFERENCEPACKAGEPRICE=${convertConferencePrice}&MODIFIEDBY=${modifyBy}`
      )
      const json = await response.json();
      const data = JSON.parse(json)[0];
      if (data && data.ReturnVal === 1) {
        dispatch(slice.actions.updateConferenceDetailsSuccess(JSON.parse(data.ReturnData)));
      } else {
        dispatch(slice.actions.hasError(JSON.parse(data.ReturnData)[0].ReturnSqlError));
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function deleteConference_Package(id, modifyBy) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());

    try {
      const response = await fetch(
        `${url}Conference_DeleteConferencePackage?CONFERENCEPACKAGEID=${id}&MODIFIEDBY=${modifyBy}`
      )
      const json = await response.json();
      const data = JSON.parse(json)[0];
      if (data && data.ReturnVal === 1) {
        dispatch(slice.actions.updateConferenceDetailsSuccess(JSON.parse(data.ReturnData)));
      } else {
        dispatch(slice.actions.hasError(JSON.parse(data.ReturnData)[0].ReturnSqlError));
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}


// ------------------------------- STAGE 7 ---------------------------------------

export function updateConference_Stage7(id, paymentInd, PERSONINCHARGE, PERSONINCHARGEEMAIL, PERSONINCHARGECONTACT, PERSONINCHARGEREMARK, bankName, accountHolder, accountNumber, modifyBy) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());

    try {
      const response = await fetch(
        `${url}Conference_UpdateConference_Stage7?CONFERENCEID=${id}&CONFERENCEPAYMENTINFOIND=${paymentInd}&PERSONINCHARGE=${PERSONINCHARGE}&PERSONINCHARGEEMAIL=${PERSONINCHARGEEMAIL}&PERSONINCHARGECONTACT=${PERSONINCHARGECONTACT}&PERSONINCHARGEREMARK=${PERSONINCHARGEREMARK}&BANKNAME=${bankName}&ACCOUNTHOLDER=${accountHolder}&ACCOUNTNUMBER=${accountNumber}&MODIFIEDBY=${modifyBy}`
      )
      const json = await response.json();
      const data = JSON.parse(json)[0];
      if (data && data.ReturnVal === 1) {
        dispatch(slice.actions.updateConferenceSuccess(JSON.parse(data.ReturnData)));
      } else {
        dispatch(slice.actions.hasError(JSON.parse(data.ReturnData)[0].ReturnSqlError));
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}


export function updateConference_Payment(id, paymentId, bankName, accountHolder, accountNumber, modifyBy) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());

    try {
      const response = await fetch(
        `${url}Conference_UpdateConferencePaymentInfo?CONFERENCEID=${id}&CONFERENCEPAYMENTINFOID=${paymentId}&BANKNAME=${bankName}&ACCOUNTHOLDER=${accountHolder}&ACCOUNTNUMBER=${accountNumber}&MODIFIEDBY=${modifyBy}`
      )
      const json = await response.json();
      const data = JSON.parse(json)[0];
      if (data && data.ReturnVal === 1) {
        dispatch(slice.actions.updateConferenceDetailsSuccess(JSON.parse(data.ReturnData)));
      } else {
        dispatch(slice.actions.hasError(JSON.parse(data.ReturnData)[0].ReturnSqlError));
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function deleteConference_Payment(id, modifyBy) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());

    try {
      const response = await fetch(
        `${url}Conference_DeleteConferencePaymentInfo?CONFERENCEPAYMENTINFOID=${id}&MODIFIEDBY=${modifyBy}`
      )
      const json = await response.json();
      const data = JSON.parse(json)[0];
      if (data && data.ReturnVal === 1) {
        dispatch(slice.actions.updateConferenceDetailsSuccess(JSON.parse(data.ReturnData)));
      } else {
        dispatch(slice.actions.hasError(JSON.parse(data.ReturnData)[0].ReturnSqlError));
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function deleteConference_PaymentCurrency(id, modifyBy) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());

    try {
      const response = await fetch(
        `${url}Conference_DeleteConferencePackageCurrency?CONFERENCEPACKAGECURRENCYID=${id}&MODIFIEDBY=${modifyBy}`
      )
      const json = await response.json();
      const data = JSON.parse(json)[0];
      if (data && data.ReturnVal === 1) {
        dispatch(slice.actions.updateConferenceDetailsSuccess(JSON.parse(data.ReturnData)));
      } else {
        dispatch(slice.actions.hasError(JSON.parse(data.ReturnData)[0].ReturnSqlError));
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}


// ------------------------------- STAGE 7 ---------------------------------------


export function updateConference_Stage8(id, pricingId, modifyBy) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());

    try {
      const response = await fetch(
        `${url}Conference_UpdateConference_Stage8?CONFERENCEID=${id}&CONFERENCEPRICINGID=${pricingId}&MODIFIEDBY=${modifyBy}`
      )
      const json = await response.json();
      const data = JSON.parse(json)[0];
      if (data && data.ReturnVal === 1) {
        dispatch(slice.actions.updateConferenceSuccess(JSON.parse(data.ReturnData)));
      } else {
        dispatch(slice.actions.hasError(JSON.parse(data.ReturnData)[0].ReturnSqlError));
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ------------------------------- STAGE 9 ---------------------------------------


export function updateConference_Stage9(id, size, background, modifyBy) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await fetch(
        `${url}Conference_UpdateConference_Stage9?CONFERENCEID=${id}&CONFERENCENAMEBADGESIZE=${size}&CONFERENCENAMEBADGEBACKGROUNDIMAGE=${background}&MODIFIEDBY=${modifyBy}`
      )
      const json = await response.json();
      const data = JSON.parse(json)[0];
      if (data && data.ReturnVal === 1) {
        dispatch(slice.actions.updateConferenceSuccess(JSON.parse(data.ReturnData)));
      } else {
        dispatch(slice.actions.hasError(JSON.parse(data.ReturnData)[0].ReturnSqlError));
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}


export function updateConference_Stage10(id, frameType, signature, name, modifyBy) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());

    try {
      const response = await fetch(
        `${url}Conference_UpdateConference_Stage10?CONFERENCEID=${id}&CONFERENCETYPEOFFRAME=${frameType}&CONFERENCECERTSIGNATUREIMAGE=${signature}&CONFERENCECERTSIGNATURENAME=${name}&MODIFIEDBY=${modifyBy}`
      )
      const json = await response.json();
      const data = JSON.parse(json)[0];
      if (data && data.ReturnVal === 1) {
        dispatch(slice.actions.updateConferenceSuccess(JSON.parse(data.ReturnData)));
      } else {
        dispatch(slice.actions.hasError(JSON.parse(data.ReturnData)[0].ReturnSqlError));
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function updateConference_Stage11(id, CONFERENCEPARTNERSHIPIND, CONFERENCEPARTNERSHIP, CONFERENCEPARTNERSHIPLOGO, CONFERENCEPARTNERSHIPCATEGORY, modifyBy) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());

    try {
      const response = await fetch(
        `${url}Conference_UpdateConference_Stage11?CONFERENCEID=${id}&CONFERENCEPARTNERSHIPIND=${CONFERENCEPARTNERSHIPIND}&CONFERENCEPARTNERSHIP=${CONFERENCEPARTNERSHIP}&CONFERENCEPARTNERSHIPLOGO=${CONFERENCEPARTNERSHIPLOGO}&CONFERENCEPARTNERSHIPCATEGORY=${CONFERENCEPARTNERSHIPCATEGORY}&MODIFIEDBY=${modifyBy}`
      )
      const json = await response.json();
      const data = JSON.parse(json)[0];
      if (data && data.ReturnVal === 1) {
        dispatch(slice.actions.updateConferenceSuccess(JSON.parse(data.ReturnData)));
      } else {
        dispatch(slice.actions.hasError(JSON.parse(data.ReturnData)[0].ReturnSqlError));
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function deleteConference_Partnership(id, modifyBy) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());

    try {
      const response = await fetch(
        `${url}Conference_DeleteConferencePartnership?CONFERENCEPARTNERSHIPID=${id}&MODIFIEDBY=${modifyBy}`
      )
      const json = await response.json();
      const data = JSON.parse(json)[0];
      if (data && data.ReturnVal === 1) {
        dispatch(slice.actions.updateConferenceDetailsSuccess(JSON.parse(data.ReturnData)));
      } else {
        dispatch(slice.actions.hasError(JSON.parse(data.ReturnData)[0].ReturnSqlError));
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}


export function deleteConference(id, modifyBy) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());

    try {
      const response = await fetch(
        `${url}Conference_DeleteConference?CONFERENCEID=${id}&MODIFIEDBY=${modifyBy}`
      )
      const json = await response.json();
      const data = JSON.parse(json)[0];
      if (data && data.ReturnVal === 1) {
        dispatch(slice.actions.deleteConferenceSuccess(JSON.parse(data.ReturnData)));
      } else {
        dispatch(slice.actions.hasError(JSON.parse(data.ReturnData)[0].ReturnSqlError));
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function clearConferenceData() {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      dispatch(slice.actions.clearConferenceDataSuccess());

    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function addConferenceMembershipOrder(userId, packageId, conferenceId, totalAmount, processingAmount, taxAmount, paidAmount, BillingData, attachmentFile, attachmentReference) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await fetch(
        `${url}Conference_AddFileOrder?USERID=${userId}&PROJECTID=${ProjectID}&PACKAGEID=${packageId}&CONFERENCEID=${conferenceId}&ORDERPROCESSINGAMOUNT=${processingAmount}&ORDERTOTALAMOUNT=${totalAmount}&ORDERPAIDAMOUNT=${paidAmount}&ORDERTAXAMOUNT=${taxAmount}&PAYMENTATTACHMENT=${attachmentFile}&PAYMENTREFENCENO=${attachmentReference}&BILLINGNAME=${BillingData.recipient}&BILLINGADDRESS1=${BillingData.addressLine1}&BILLINGADDRESS2=${BillingData.addressLine2}&BILLINGCONTACT=${BillingData.contact}&BILLINGEMAIL=${BillingData.email}`
      )
      const json = await response.json();
      const data = JSON.parse(json)[0];
      if (data && data.ReturnVal === 1) {
        dispatch(slice.actions.getConferenceMembershipOrderSuccess(JSON.parse(data.ReturnData)[0]));
      } else {
        dispatch(slice.actions.hasError(JSON.parse(data.ReturnData).ReturnSqlError));
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}


export function addConferenceMembershipPaynetOrder(userId, packageId, conferenceId, totalAmount, processingAmount, taxAmount, paidAmount, BillingData, paynetData) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await fetch(
        `${url}Conference_AddFPXOrder?USERID=${userId}&PROJECTID=${ProjectID}&PACKAGEID=${packageId}&CONFERENCEID=${conferenceId}&ORDERPROCESSINGAMOUNT=${processingAmount}&ORDERTOTALAMOUNT=${totalAmount}&ORDERPAIDAMOUNT=${paidAmount}&ORDERTAXAMOUNT=${taxAmount}&PAYNETTRANSACTIONID=${paynetData.transactionId}&PAYNETREFERENCE=${paynetData.recipientReference}&PAYNETDESCRIPTION=${paynetData.paymentDescription}&PAYNETMESSAGEID=${paynetData.messageId}&PAYNETLOCATIONLAT=${paynetData.latitude}&PAYNETLOCATIONLNG=${paynetData.longitude}&PAYNETIP=${paynetData.userIPAddress}&TRANSACTIONUUID=${paynetData.endToEndId}&BANKCODE=${paynetData.custbankCode}&BANKNAME=${paynetData.custbankName}&BILLINGNAME=${BillingData.recipient}&BILLINGADDRESS1=${BillingData.addressLine1}&BILLINGADDRESS2=${BillingData.addressLine2}&BILLINGCONTACT=${BillingData.contact}&BILLINGEMAIL=${BillingData.email}`
      )
      const json = await response.json();
      const data = JSON.parse(json)[0];
      if (data && data.ReturnVal === 1) {
        dispatch(slice.actions.addConferenceMembershipPaynetOrderSuccess(JSON.parse(data.ReturnData)[0]));
      } else {
        dispatch(slice.actions.hasError(JSON.parse(data.ReturnData).ReturnSqlError));
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function updateOrderPaymentStatus(userId, conferenceId, transactionuuid, tansactionstatus) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await fetch(
        `${url}Conference_UpdateFPXOrderPaymentStatus?TRANSACTIONUUID=${transactionuuid}&TRANSACTIONSTATUS=${tansactionstatus}&CONFERENCEID=${conferenceId}&USERID=${userId}`
      )
      const json = await response.json();
      const data = JSON.parse(json)[0];
      console.log()
      if (data && data.ReturnVal === 1) {
        dispatch(slice.actions.updateOrderPaymentStatusSuccess(JSON.parse(data.ReturnData)[0]));
      } else {
        dispatch(slice.actions.hasError(JSON.parse(data.ReturnData).ReturnSqlError));
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}


export function getOrderDetailByUUID(transactionuuid) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await fetch(
        `${url}Order_ViewPaymentDetailsByUUID?TRANSACTIONUUID=${transactionuuid}&PROJECTID=${ProjectID}`
      )
      const json = await response.json();
      const data = JSON.parse(json)[0];
      if (data && data.ReturnVal === 1) {
        dispatch(slice.actions.getOrderDetailByUUIDSuccess(JSON.parse(data.ReturnData)[0]));
      } else {
        dispatch(slice.actions.hasError(JSON.parse(data.ReturnData).ReturnSqlError));
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}



// ----------------------------------------------------------------------

export function getConferenceDetails(id, userId) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await fetch(
        `${url}Conference_ViewConferenceDashboard?CONFERENCEID=${id}&USERID=${userId}`
      )
      const json = await response.json();
      const data = JSON.parse(json)[0];
      if (data && data.ReturnVal === 1) {
        dispatch(slice.actions.getConferenceDetailsSuccess(JSON.parse(data.ReturnData)[0]));
      } else {
        dispatch(slice.actions.hasError(JSON.parse(data.ReturnData).ReturnSqlError));
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function getConferenceStatus(id, userId) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await fetch(
        `${url}General_ViewConferenceStatus`
      )
      const json = await response.json();
      const data = JSON.parse(json)[0];
      if (data && data.ReturnVal === 0) {
        dispatch(slice.actions.getConferenceStatusSuccess(JSON.parse(data.ReturnData)[0]));
      } else {
        dispatch(slice.actions.hasError(JSON.parse(data.ReturnData).ReturnSqlError));
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function getConferenceAssistant(id) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await fetch(
        `${url}Conference_ViewConferenceUserAccess?CONFERENCEID=${id}`
      )
      const json = await response.json();
      const data = JSON.parse(json)[0];
      if (data) {
        dispatch(slice.actions.getConferenceAssistantSuccess(JSON.parse(data.ReturnData)));
      } else {
        dispatch(slice.actions.hasError(JSON.parse(data.ReturnData).ReturnSqlError));
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function addConferenceAssistant(id, email, roleId, createdBy) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await fetch(
        `${url}Conference_AddConferenceUserAccess?CONFERENCEID=${id}&USEREMAIL=${email}&CONFERENCEROLEID=${roleId}&CREATEDBY=${createdBy}`
      )
      const json = await response.json();
      const data = JSON.parse(json)[0];
      if (data && data.ReturnVal === 1) {
        dispatch(slice.actions.addConferenceAssistantSuccess(JSON.parse(data.ReturnData)));
      } else {
        dispatch(slice.actions.hasError(JSON.parse(data.ReturnData).ReturnSqlError));
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function deleteConferenceAssistant(id, modifiedBy) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await fetch(
        `${url}Conference_DeleteConferenceUserAccess?CONFERENCEUSERACCESSID=${id}&MODIFIEDBY=${modifiedBy}`
      )
      const json = await response.json();
      const data = JSON.parse(json)[0];
      if (data && data.ReturnVal === 1) {
        dispatch(slice.actions.removeConferenceAssistantSuccess(JSON.parse(data.ReturnData)[0]));
      } else {
        dispatch(slice.actions.hasError(JSON.parse(data.ReturnData).ReturnSqlError));
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function updateConferenceActivation(id, activeId, modifiedBy) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await fetch(
        `${url}Conference_UpdateConferenceActivation?CONFERENCEID=${id}&ISACTIVE=${activeId}&MODIFIEDBY=${modifiedBy}`
      )
      const json = await response.json();
      const data = JSON.parse(json)[0];
      if (data && data.ReturnVal === 1) {
        dispatch(slice.actions.updateConferenceActivationSuccess(JSON.parse(data.ReturnData)[0]));
      } else {
        dispatch(slice.actions.hasError(JSON.parse(data.ReturnData).ReturnSqlError));
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function userAcceptAction(id, userId, statusId, modifyBy) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await fetch(
        `${url}Conference_UpdateConferenceUserAccessStatus?CONFERENCEID=${id}&USERID=${userId}&USERSTATUSID=${statusId}&MODIFIEDBY=${modifyBy}`
      )
      const json = await response.json();
      const data = JSON.parse(json)[0];
      if (data) {
        dispatch(slice.actions.userAcceptActionSuccess(data));
      } else {
        dispatch(slice.actions.hasError(JSON.parse(data.ReturnData).ReturnSqlError));
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function unsubscribeUpdateConferenceActivation() {
  return async (dispatch) => {
    dispatch(slice.actions.unsubscribeUpdateConferenceActivation());
  }
}

export function clearConferenceOrderData() {
  return async (dispatch) => {
    dispatch(slice.actions.clearConferenceData());
  }
}

export function viewAllTopicByConference(id) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await fetch(
        `${url}Conference_ViewConferencePaper?CONFERENCEID=${id}`
      )
      const json = await response.json();
      const data = JSON.parse(json)[0];
      if (data) {
        dispatch(slice.actions.getConferenceTopicSuccess(JSON.parse(data.ReturnData)));
      } else {
        dispatch(slice.actions.hasError(JSON.parse(data.ReturnData).ReturnSqlError));
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getPackageValidity(userId) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await fetch(
        `${url}Conference_CheckPackageValidity?USERID=${userId}&PROJECTID=${ProjectID}`
      )
      const json = await response.json();
      const data = JSON.parse(json)[0];
      if (data) {
        dispatch(slice.actions.getPackageValiditySuccess(JSON.parse(data.ReturnData)[0]));
      } else {
        dispatch(slice.actions.hasError(JSON.parse(data.ReturnData).ReturnSqlError));
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function clearPackageValidity() {
  return async (dispatch) => {
    dispatch(slice.actions.clearPackageValiditySuccess());
  };
}

export function clearUserAcceptAction() {
  return async (dispatch) => {
    dispatch(slice.actions.clearUserAcceptActionSuccess());
  };
}
