// @mui
import { Stack, Box } from '@mui/material';
// config
import { NAV } from '../../../config-global';
// utils
import { hideScrollbarX } from '../../../utils/cssStyles';
// components
import Logo from '../../../components/logo';
import { NavSectionMini } from '../../../components/nav-section';
//
import getNavConfig from './config-navigation';
import { useAuthContext } from '../../../auth/useAuthContext';
import NavToggleButton from './NavToggleButton';

// ----------------------------------------------------------------------

export default function NavMini() {
  const { user, selectedUserRoleID } = useAuthContext();
  const NavConfig = getNavConfig(selectedUserRoleID);

  return (
    <Box
      component="nav"
      sx={{
        flexShrink: { lg: 0 },
        width: { lg: NAV.W_DASHBOARD_MINI },
      }}
    >
      <NavToggleButton
        sx={{
          top: 22,
          left: NAV.W_DASHBOARD_MINI - 12,
        }}
      />

      <Stack
        sx={{
          pb: 2,
          height: 1,
          position: 'fixed',
          width: NAV.W_DASHBOARD_MINI,
          borderRight: (theme) => `dashed 1px ${theme.palette.divider}`,
          ...hideScrollbarX,
        }}
      >
        <Logo sx={{ mx: 'auto', my: 2 }} />

        <NavSectionMini data={NavConfig} />
      </Stack>
    </Box>
  );
}


// import { useEffect } from 'react';
// // @mui
// import { Stack, Box } from '@mui/material';
// // config
// import { NAV } from '../../../config-global';
// // utils
// import { hideScrollbarX } from '../../../utils/cssStyles';
// // components
// import Logo from '../../../components/logo';
// import { NavSectionMini } from '../../../components/nav-section';
// //
// import { useAuthContext } from '../../../auth/useAuthContext';
// import NavToggleButton from './NavToggleButton';
// import { useDispatch, useSelector } from "../../../redux/store";
// import { getUserPageView, unsubscribeUserPage } from '../../../redux/slices/user';

// // ----------------------------------------------------------------------

// export default function NavMini() {
//   const dispatch = useDispatch();

//   const { user } = useAuthContext();

//   const { userPage } = useSelector((state) => state.user);

//   useEffect(() => {
//     dispatch(unsubscribeUserPage())
//     dispatch(getUserPageView(user.UserID, 1))
//   }, [dispatch, user]);


//   return (
//     <Box
//       component="nav"
//       sx={{
//         flexShrink: { lg: 0 },
//         width: { lg: NAV.W_DASHBOARD_MINI },
//       }}
//     >
//       <NavToggleButton
//         sx={{
//           top: 22,
//           left: NAV.W_DASHBOARD_MINI - 12,
//         }}
//       />

//       <Stack
//         sx={{
//           pb: 2,
//           height: 1,
//           position: 'fixed',
//           width: NAV.W_DASHBOARD_MINI,
//           borderRight: (theme) => `dashed 1px ${theme.palette.divider}`,
//           ...hideScrollbarX,
//         }}
//       >
//         <Logo sx={{ mx: 'auto', my: 2 }} />

//         <NavSectionMini data={userPage} />
//       </Stack>
//     </Box>
//   );
// }
