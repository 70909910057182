import { createSlice } from '@reduxjs/toolkit';
// utils
import { ProjectID, ServerConfiguration } from '../../utils/serverConfig';

// ----------------------------------------------------------------------

const url = ServerConfiguration.testingServerUrl;
const PROJECTID = 1;
const initialState = {
  isLoading: false,
  error: null,
  tasks: [],
  task: null,
  taskByID: null,
  createTask: null,
  deleteTask: null,
  response: null,
  returnReview: null,
  updateReview: null,
  status: [],
  suggestedReviewer: [],
  inviteReviewer: null,
  review: null,
  inviteAction: [],
  taskByPaperID: [],
  reviewDetails: [],
  taskStatus: [],
  paperList: [],
  invitedReviewers: [],
};

const slice = createSlice({
  name: 'task',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    // GET TASKS
    getTasksSuccess(state, action) {
      state.isLoading = false;
      state.tasks = action.payload;
    },

    // GET TASK
    getTaskSuccess(state, action) {
      state.isLoading = false;
      state.task = action.payload;
    },

    // GET TASK BY ID
    getTaskByIDSuccess(state, action) {
      state.isLoading = false;
      state.taskByID = action.payload;
    },

    getTaskByPaperIDSuccess(state, action) {
      state.isLoading = false;
      state.taskByPaperID = action.payload;
    },

    getSuggestedReviewerSuccess(state, action) {
      state.isLoading = false;
      state.suggestedReviewer = action.payload;
    },

    // CREATE TASK
    createTaskSuccess(state, action) {
      state.isLoading = false;
      state.createTask = action.payload;
    },

    // DELETE TASK
    deleteTaskSuccess(state, action) {
      state.isLoading = false;
      state.deleteTask = action.payload;
    },

    // RESPONSE
    responseSuccess(state, action) {
      state.isLoading = false;
      state.response = action.payload;
    },

    // RETURN REVIEW
    returnReviewSuccess(state, action) {
      state.isLoading = false;
      state.returnReview = action.payload;
    },

    // UPDATE REVIEW
    updateReviewSuccess(state, action) {
      state.isLoading = false;
      state.updateReview = action.payload;
    },

    // GET STATUS
    getStatusSuccess(state, action) {
      state.isLoading = false;
      state.status = action.payload;
    },

    // SEND REVIEW
    sendReviewSuccess(state, action) {
      state.isLoading = false;
      state.review = action.payload;
    },

    // UNSUBSCRIBE SEND REVIEW
    unsubscribeSendReviewSuccess(state, action) {
      state.isLoading = false;
      state.review = null;
    },

    // Invite Reviewer
    inviteSuccess(state, action) {
      state.isLoading = false;
      state.inviteReviewer = action.payload;
    },

    inviteEditorSuccess(state, action) {
      state.isLoading = false;
      state.inviteAction = action.payload;
    },

    viewReviewSuccess(state, action) {
      state.isLoading = false;
      state.reviewDetails = action.payload;
    },

    // CLEAR RETURN INVITE REVIEWER ACTION
    clearInviteAction(state) {
      state.inviteAction = null;
    },

    // GET TASK STATUS
    getTaskStatusSuccess(state, action) {
      state.isLoading = false;
      state.taskStatus = action.payload;
    },

    // GET PAPER LIST SUCCESS
    gerPaperListSuccess(state, action) {
      state.isLoading = false;
      state.paperList = action.payload;
    },

    // CLEAR INVITE REVIEWER
    clearInviteReviewer(state) {
      state.inviteReviewer = null;
    },

    // GET INVITED REVIEWERS SUCCESS
    getInvitedReviewersSuccess(state, action) {
      state.isLoading = false;
      state.invitedReviewers = action.payload;
    },
  },
});

// Reducer
export default slice.reducer;

// Actions
export const {
  clearInviteReviewer,
} = slice.actions;

// ----------------------------------------------------------------------

export function getTasks(userId) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await fetch(
        `${url}Paper_Task_ViewByUserID?USERID=${userId}&PROJECTID=${ProjectID}`
      )
      const json = await response.json();
      const data = JSON.parse(json)[0];
      if (data && data.ReturnVal === 1) {
        dispatch(slice.actions.getTasksSuccess(JSON.parse(data.ReturnData)));
      } else {
        dispatch(slice.actions.hasError(JSON.parse(data.ReturnData)[0].ReturnSqlError));
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}


export function viewTaskByID(taskId) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await fetch(
        `${url}Paper_Task_ViewByTaskID?TASKID=${taskId}&PROJECTID=${ProjectID}`
      )
      const json = await response.json();
      const data = JSON.parse(json)[0];
      if (data && data.ReturnVal === 1) {
        dispatch(slice.actions.getTaskByIDSuccess(JSON.parse(data.ReturnData)[0]));
      } else {
        dispatch(slice.actions.hasError(JSON.parse(data.ReturnData)[0].ReturnSqlError));
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function viewTaskByPaperID(paperid, email) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await fetch(
        `${url}Paper_Task_ViewByPaperID?PAPERID=${paperid}&USEREMAIL=${email}&PROJECTID=${ProjectID}`
      )
      const json = await response.json();
      const data = JSON.parse(json)[0];
      if (data && data.ReturnVal === 1) {
        dispatch(slice.actions.getTaskByPaperIDSuccess(JSON.parse(data.ReturnData)));
      } else {
        dispatch(slice.actions.hasError(JSON.parse(data.ReturnData)[0].ReturnSqlError));
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}


// --------------------------------- Invite Reviewer -------------------------------------

export function viewSuggestedReviewer(paperId) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await fetch(
        `${url}Paper_Task_ViewSuggestedReviewer?PAPERID=${paperId}&PROJECTID=${ProjectID}`
      )
      const json = await response.json();
      const data = JSON.parse(json)[0];
      if (data && data.ReturnVal === 1) {
        dispatch(slice.actions.getSuggestedReviewerSuccess(JSON.parse(data.ReturnData)));
      } else {
        dispatch(slice.actions.hasError(JSON.parse(data.ReturnData)[0].ReturnSqlError));
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}


export function invitePaperReviewer(
  email,
  paperId,
  modifiedBy,
  status,
  role,
  type,
  subject,
  body,
  dueDate
) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await fetch(
        `${url}Paper_Task_RequestAndAssignment?USEREMAIL=${email}&PROJECTID=${PROJECTID}&PAPERID=${paperId}&MODIFYBY=${modifiedBy}&TASKSTATUS=${status}&TASKUSERROLE=${role}&TYPE=${type}&SUBJECT=${subject}&BODY=${body}&TASKDUEDATE=${dueDate}`
      );
      const json = await response.json();
      const data = JSON.parse(json);
      if (data[0].ReturnVal === 1) {
        dispatch(slice.actions.inviteSuccess(JSON.parse(data[0].ReturnData)));
      } else {
        dispatch(slice.actions.hasError(data[0].ReturnSqlError));
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function createTask(email, paperId, modifiedBy, status, role, type, subject, body) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await fetch(
        `${url}Paper_Task_RequestAndAssignment?USEREMAIL=${email}&PROJECTID=${ProjectID}&PAPERID=${paperId}&MODIFYBY=${modifiedBy}&TASKSTATUS=${status}&TASKUSERROLE=${role}&TYPE=${type}&SUBJECT=${subject}&BODY=${body}`
      )
      const json = await response.json();
      const data = JSON.parse(json)[0];
      if (data && data.ReturnVal === 1) {
        dispatch(slice.actions.createTaskSuccess(JSON.parse(data.ReturnData)[0]));
      } else {
        dispatch(slice.actions.hasError(JSON.parse(data.ReturnData)[0].ReturnSqlError));
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function deleteTask(taskId, modifiedBy) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await fetch(
        `${url}Paper_Task_AssignEditorDelete?USERTASKID=${taskId}&MODIFYBY=${modifiedBy}`
      )
      const json = await response.json();
      const data = JSON.parse(json)[0];
      if (data && data.ReturnVal === 1) {
        dispatch(slice.actions.deleteTaskSuccess(JSON.parse(data.ReturnData)));
      } else {
        dispatch(slice.actions.hasError(JSON.parse(data.ReturnData)[0].ReturnSqlError));
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function reviewerResponse(paperId, useremail, acceptInd, status, date) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await fetch(
        `${url}Paper_Task_InviteReviewerRespond?PAPERID=${paperId}&USEREMAIL=${useremail}&ISTASKACCEPTED=${acceptInd}&TASKSTATUS=${status}&TASKEXTENDDATE=${date}&PROJECTID=${PROJECTID}`
      )
      const json = await response.json();
      const data = JSON.parse(json)[0];
      if (data && data.ReturnVal === 1) {
        dispatch(slice.actions.responseSuccess(JSON.parse(data.ReturnData)));
      } else {
        dispatch(slice.actions.hasError(JSON.parse(data.ReturnData)[0].ReturnSqlError));
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function returnReview(emailType, subject, body, modifiedBy, paperId, taskId, draftInd, status, emailId, acceptInd) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await fetch(
        `${url}Paper_Task_ReviewerReturn?EMAILTYPE=${emailType}&SUBJECT=${subject}&BODY=${body}&MODIFYBY=${modifiedBy}&PAPERID=${paperId}&TASKID=${taskId}&ISDRAFT=${draftInd}&TASKSTATUS=${status}&EMAILID=${emailId}&PROJECTID=${ProjectID}&ISPAPERACCEPTED=${acceptInd}`
      )
      const json = await response.json();
      const data = JSON.parse(json)[0];
      if (data && data.ReturnVal === 1) {
        dispatch(slice.actions.returnReviewSuccess(JSON.parse(data.ReturnData)));
      } else {
        dispatch(slice.actions.hasError(JSON.parse(data.ReturnData)[0].ReturnSqlError));
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function updateReview(reviewId, modifiedBy, paperId, title, description, draftInd, taskId, acceptInd, status, subject, body) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await fetch(
        `${url}Paper_Task_ReviewerUpdate?REVIEWID=${reviewId}&MODIFYBY=${modifiedBy}&PAPERID=${paperId}&REVIEWTITLE=${title}&REVIEWDESCRIPTION=${description}&PROJECTID=${ProjectID}&ISDRAFT=${draftInd}&TASKID=${taskId}&ISPAPERACCEPT=${acceptInd}&TASKSTATUS=${status}&SUBJECT=${subject}&BODY=${body}`
      )
      const json = await response.json();
      const data = JSON.parse(json)[0];
      if (data && data.ReturnVal === 1) {
        dispatch(slice.actions.updateReviewSuccess(JSON.parse(data.ReturnData)));
      } else {
        dispatch(slice.actions.hasError(JSON.parse(data.ReturnData)[0].ReturnSqlError));
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function getStatus(type) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await fetch(
        `${url}General_View_PaperStatusByPage?PAGE=${type}`
      )
      const json = await response.json();
      const data = JSON.parse(json)[0];
      if (data && data.ReturnVal === 1) {
        dispatch(slice.actions.getStatusSuccess(JSON.parse(data.ReturnData)));
      } else {
        dispatch(slice.actions.hasError(JSON.parse(data.ReturnData)[0].ReturnSqlError));
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function sendReview(reviewId, user, script, questions, answers, isDraft, isAccepted, subject, body) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await fetch(
        `${url}Paper_ReviewerUpdateReview?REVIEWID=${reviewId}&MODIFYBY=${user.UserID}&PAPERID=${script.PaperID}&REVIEWTITLE=${questions}&REVIEWDESCRIPTION=${answers}&PROJECTID=${user.ProjectID}&ISDRAFT=${isDraft}&TASKID=${script.UserTaskID}&ISPAPERACCEPT=${isAccepted}&TASKSTATUS=${script.TaskStatus}&SUBJECT=${subject}&BODY=${body}`
      )
      const json = await response.json();
      const data = JSON.parse(json)[0];
      if (data && data.ReturnVal === 1) {
        dispatch(slice.actions.sendReviewSuccess(JSON.parse(data.ReturnData)));
      } else {
        dispatch(slice.actions.hasError(JSON.parse(data.ReturnData)[0].ReturnSqlError));
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function viewReviewByPaper(useremail, paperid) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await fetch(
        `${url}Paper_AuthorPaper_ViewPaperReview?USEREMAIL=${useremail}&PAPERID=${paperid}`
      )
      const json = await response.json();
      const data = JSON.parse(json)[0];
      if (data && data.ReturnVal === 1) {
        dispatch(slice.actions.viewReviewSuccess(JSON.parse(data.ReturnData)));
      } else {
        dispatch(slice.actions.hasError(JSON.parse(data.ReturnData)[0].ReturnSqlError));
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}


// ----------------------------------------------------------------------

export function unsubscribeSendReview() {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      dispatch(slice.actions.unsubscribeSendReviewSuccess());
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function inviteEditor(email, modifyBy, body, subject) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await fetch(
        `${url}User_InviteEditor?USEREMAIL=${email}&MODIFYBY=${modifyBy}&PROJECTID=${PROJECTID}&BODY=${body}&SUBJECT=${subject}`
      );
      const json = await response.json();
      const data = JSON.parse(json);

      if (data && data[0].ReturnVal === 1) {
        dispatch(slice.actions.inviteEditorSuccess(JSON.parse(data[0].ReturnData)));
      } else {
        dispatch(slice.actions.hasError(JSON.parse(data.ReturnData)[0].ReturnSqlError));
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function getTaskStatus() {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await fetch(`${url}Paper_Task_ViewTaskStatus`);
      const json = await response.json();
      const data = JSON.parse(json)[0];
      if (data && data.ReturnVal === 1) {
        dispatch(slice.actions.getTaskStatusSuccess(JSON.parse(data.ReturnData)));
      } else {
        dispatch(slice.actions.hasError(JSON.parse(data.ReturnData)[0].ReturnSqlError));
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function getPaperList(userId, projectId, journalId) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await fetch(
        `${url}Paper_AuthorPaper_PaperListByUserID?USERID=${userId}&PROJECTID=${projectId}&JOURNALID=${journalId}`
      );
      const json = await response.json();
      const data = JSON.parse(json)[0];
      if (data && data.ReturnVal === 1) {
        dispatch(slice.actions.gerPaperListSuccess(JSON.parse(data.ReturnData)));
      } else {
        dispatch(slice.actions.hasError(JSON.parse(data.ReturnData)[0].ReturnSqlError));
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function getInvitedReviewers(userTypeId, paperId) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await fetch(
        `${url}Paper_Task_ViewByUserTypeID?USERTYPEID=${userTypeId}&PAPERID=${paperId}&PROJECTID=${ProjectID}`
      );
      const json = await response.json();
      const data = JSON.parse(json)[0];
      if (data && data.ReturnVal === 1) {
        dispatch(slice.actions.getInvitedReviewersSuccess(JSON.parse(data.ReturnData)));
      } else {
        dispatch(slice.actions.hasError(JSON.parse(data.ReturnData)[0].ReturnSqlError));
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}