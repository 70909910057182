import { createSlice } from '@reduxjs/toolkit';
// utils
import axios from '../../utils/axios';
import { ServerConfiguration } from '../../utils/serverConfig';

// ----------------------------------------------------------------------

const url = ServerConfiguration.testingServerUrl;

const initialState = {
  isLoading: false,
  error: null,
  questions: null,
  addTemplate: null,
  updateTemplate: null,
  updateTemplateStatus: null,
  deleteQuestionDetails: null,
  deleteQuestion: null,
  deleteAnswerSelection: null,
};

const slice = createSlice({
  name: 'question',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    // GET QUESTIONS
    getQuestionsSuccess(state, action) {
      state.isLoading = false;
      state.questions = action.payload;
    },

    // ADD TEMPLATE
    addTemplateSuccess(state, action) {
      state.isLoading = false;
      state.addTemplate = action.payload;
    },

    // UPDATE TEMPLATE
    updateTemplateSuccess(state, action) {
      state.isLoading = false;
      state.updateTemplate = action.payload;
    },

    // UPDATE TEMPLATE STATUS
    updateTemplateStatusSuccess(state, action) {
      state.isLoading = false;
      state.updateTemplateStatus = action.payload;
    },

    // DELETE QUESTION DETAILS
    deleteQuestionDetailsSuccess(state, action) {
      state.isLoading = false;
      state.deleteQuestionDetails = action.payload;
    },

    // DELETE QUESTION
    deleteQuestionSuccess(state, action) {
      state.isLoading = false;
      state.deleteQuestion = action.payload;
    },

    // DELETE ANSWER SELECTION
    deleteAnswerSelectionSuccess(state, action) {
      state.isLoading = false;
      state.deleteAnswerSelection = action.payload;
    },
  },
});

// Reducer
export default slice.reducer;

// ----------------------------------------------------------------------

export function getQuestions(templateId, projectId, isActive) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await fetch(
        `${url}Paper_QuestionTemplate_ViewAll?QUESTIONTEMPLATEID=${templateId}&PROJECTID=${projectId}&ISACTIVE=${isActive}`
      )
      const json = await response.json();
      const data = JSON.parse(json)[0];
      if (data && data.ReturnVal === 1) {
        dispatch(slice.actions.getQuestionsSuccess(JSON.parse(data.ReturnData)));
      } else {
        dispatch(slice.actions.hasError(JSON.parse(data.ReturnData)[0].ReturnSqlError));
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function addTemplate(templateTitle, projectId, modifiedBy, isQuestionUsed, question, answerTypeId, answerSelection) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await fetch(
        `${url}Paper_QuestionTemplate_Add?TEMPLATETITLE=${templateTitle}&PROJECTID=${projectId}&MODIFIEDBY=${modifiedBy}&ISQUESTIONUSE=${isQuestionUsed}&QUESTION=${question}&ANSWERTYPEID=${answerTypeId}&ANSWERSELECTION=${answerSelection}`
      )
      const json = await response.json();
      const data = JSON.parse(json)[0];
      if (data && data.ReturnVal === 1) {
        dispatch(slice.actions.addTemplateSuccess(JSON.parse(data.ReturnData)));
      } else {
        dispatch(slice.actions.hasError(JSON.parse(data.ReturnData)[0].ReturnSqlError));
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function updateTemplate(templateId, templateTitle, projectId, modifiedBy, questionId, answerTypeId, question, isUpdateAnswerType, questionDetailsId, answerSelection) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await fetch(
        `${url}Paper_QuestionTemplate_UpdateQuestionTemplate?QuestionTemplateID=${templateId}&QUESTIONTEMPLATETITLE=${templateTitle}&PROJECTID=${projectId}&MODIFIEDBY=${modifiedBy}&QUESTIONID=${questionId}&QUESTIONANSWERTYPEID=${answerTypeId}&QUESTION=${question}&ISUPDATEANSWERTYPE=${isUpdateAnswerType}&QUESTIONDETAILSID=${questionDetailsId}&ANSWERSELECTION=${answerSelection}`
      )
      const json = await response.json();
      const data = JSON.parse(json)[0];
      if (data && data.ReturnVal === 1) {
        dispatch(slice.actions.updateTemplateSuccess(JSON.parse(data.ReturnData)));
      } else {
        dispatch(slice.actions.hasError(JSON.parse(data.ReturnData)[0].ReturnSqlError));
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function updateTemplateStatus(templateId, modifiedBy, isQuestionUsed) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await fetch(
        `${url}Paper_QuestionTemplate_UpdateTemplateStatus?QUESTIONTEMPLATEID=${templateId}&MODIFIEDBY=${modifiedBy}&ISQUESTIONUSE=${isQuestionUsed}`
      )
      const json = await response.json();
      const data = JSON.parse(json)[0];
      if (data && data.ReturnVal === 1) {
        dispatch(slice.actions.updateTemplateStatusSuccess(JSON.parse(data.ReturnData)));
      } else {
        dispatch(slice.actions.hasError(JSON.parse(data.ReturnData)[0].ReturnSqlError));
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function deleteQuestionDetails(questionDetailsId, modifiedBy) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await fetch(
        `${url}Paper_QuestionTemplate_DeleteQuestionDetails?QUESTIONID=${questionDetailsId}&MODIFIEDBY=${modifiedBy}`
      )
      const json = await response.json();
      const data = JSON.parse(json)[0];
      if (data && data.ReturnVal === 1) {
        dispatch(slice.actions.deleteQuestionDetailsSuccess(JSON.parse(data.ReturnData)));
      } else {
        dispatch(slice.actions.hasError(JSON.parse(data.ReturnData)[0].ReturnSqlError));
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function deleteQuestion(templateId, modifiedBy) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await fetch(
        `${url}Paper_QuestionTemplate_DeleteQuestion?QUESTIONTEMPLATEID=${templateId}&MODIFIEDBY=${modifiedBy}`
      )
      const json = await response.json();
      const data = JSON.parse(json)[0];
      if (data && data.ReturnVal === 1) {
        dispatch(slice.actions.deleteQuestionSuccess(JSON.parse(data.ReturnData)));
      } else {
        dispatch(slice.actions.hasError(JSON.parse(data.ReturnData)[0].ReturnSqlError));
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------
export function deleteAnswerSelection(questionDetailsId, modifiedBy) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await fetch(
        `${url}Paper_QuestionTemplate_DeleteAnswerSelection?QUESTIONDETAILSID=${questionDetailsId}&MODIFIEDBY=${modifiedBy}`
      )
      const json = await response.json();
      const data = JSON.parse(json)[0];
      if (data && data.ReturnVal === 1) {
        dispatch(slice.actions.deleteAnswerSelectionSuccess(JSON.parse(data.ReturnData)));
      } else {
        dispatch(slice.actions.hasError(JSON.parse(data.ReturnData)[0].ReturnSqlError));
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}