// import PropTypes from 'prop-types';
// // form
// import { useFormContext, Controller } from 'react-hook-form';
// // @mui
// import { DatePicker } from '@mui/x-date-pickers';
// import { TextField } from '@mui/material';

// // ----------------------------------------------------------------------

// RHFDatePicker.propTypes = {
//   name: PropTypes.string,
//   size: PropTypes.string,
//   margin: PropTypes.string,
//   helperText: PropTypes.node,
//   disabled: PropTypes.bool,
// };

// export default function RHFDatePicker({ name, helperText, size, margin, disabled, ...other }) {
//   const { control } = useFormContext();

//   return (
//     <Controller
//       name={name}
//       control={control}
//       render={({ field, fieldState: { error } }) => (
//         <DatePicker
//           value={field.value}
//           onChange={(newValue) => {
//             field.onChange(newValue);
//           }}
//           disabled={disabled}
//           inputFormat='dd/MM/yyyy'
//           renderInput={(params) => (
//             <TextField
//               {...params}
//               {...field}
//               size={size || "medium"}
//               margin={margin || "normal"}
//               fullWidth
//               disabled={disabled}
//               error={!!error}
//               helperText={error ? error?.message : helperText}
//             />
//           )}
//           {...other}
//         />
//       )}
//     />
//   );
// }

import PropTypes from 'prop-types';
import { useFormContext, Controller } from 'react-hook-form';
import { DatePicker } from '@mui/x-date-pickers';
import { TextField } from '@mui/material';

RHFDatePicker.propTypes = {
  name: PropTypes.string.isRequired,
  helperText: PropTypes.node,
  size: PropTypes.string,
  margin: PropTypes.string,
  disabled: PropTypes.bool,
  minDate: PropTypes.oneOfType([PropTypes.instanceOf(Date), PropTypes.string]),
  maxDate: PropTypes.oneOfType([PropTypes.instanceOf(Date), PropTypes.string]),
};

export default function RHFDatePicker({
  name,
  helperText,
  size,
  margin,
  disabled,
  minDate,
  maxDate,
  ...other
}) {
  const { control } = useFormContext();

  return (
    <Controller
      name={name}
      control={control}
      render={({ field, fieldState: { error } }) => (
        <DatePicker
          value={field.value}
          onChange={(newValue) => {
            field.onChange(newValue);
          }}
          disabled={disabled}
          inputFormat='dd/MM/yyyy'
          renderInput={(params) => (
            <TextField
              {...params}
              {...field}
              size={size || "medium"}
              margin={margin || "normal"}
              fullWidth
              disabled={disabled}
              error={!!error}
              helperText={error ? error?.message : helperText}
            />
          )}
          minDate={minDate}
          maxDate={maxDate}
          {...other}
        />
      )}
    />
  );
}
