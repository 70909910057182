
import { createSlice } from '@reduxjs/toolkit';
// utils
import axios from '../../utils/axios';
import { ServerConfiguration, ProjectID } from '../../utils/serverConfig';
// ----------------------------------------------------------------------
const url = ServerConfiguration.testingServerUrl;

const initialState = {
    isLoading: false,
    error: null,

    billingList: [],
    billingUpdated: null,
    bankList: [],
    methodSelection: 1,
    bankSelected: null,
    paymentData: null,
    initiatePayment: null,
    tokenValidity: null,
    updateTokenAction: null,
    transactionStatus: null,
    bankPageKey: "",
    statusNotification: null

};

const slice = createSlice({
    name: 'billing',
    initialState,
    reducers: {
        // START LOADING
        startLoading(state) {
            state.isLoading = true;
        },

        // HAS ERROR
        hasError(state, action) {
            state.isLoading = false;
            state.error = action.payload;
        },

        getBillingSuccess(state, action) {
            state.isLoading = false;
            state.billingList = action.payload;
        },

        updateBillingSuccess(state, action) {
            state.isLoading = false;
            state.billingUpdated = action.payload;
        },

        unSubscribeBillingSuccess(state, action) {
            state.isLoading = false;
            state.billingList = [];
            state.billingUpdated = null;
        },

        paynetInitiatePaymentSuccess(state, action) {
            state.isLoading = false;
            state.initiatePayment = action.payload;
        },

        checkTokenValiditySuccess(state, action) {
            state.isLoading = false;
            state.tokenValidity = action.payload;
        },

        requestBankListSuccess(state, action) {
            state.isLoading = false;
            state.requestbankList = action.payload;
        },

        requestTransactionStatusSuccess(state, action) {
            state.isLoading = false;
            state.transactionStatus = action.payload;
        },

        requestPaymentStatusNotificationSuccess(state, action) {
            state.isLoading = false;
            state.statusNotification = action.payload;
        },

        setSelectedBank(state, action) {
            state.isLoading = false;
            state.bankSelected = action.payload;
        },

        setMethodSelection(state, action) {
            state.isLoading = false;
            state.methodSelection = action.payload;
        },

        setPaymentData(state, action) {
            state.isLoading = false;
            state.paymentData = action.payload;
        },

        requestTokenSuccess(state, action) {
            state.isLoading = false;
            state.token = action.payload;
        },

        setBankPageKey(state, action) {
            state.isLoading = false;
            state.bankPageKey = action.payload;
        },

        setBankList(state, action) {
            state.isLoading = false;
            state.bankList = action.payload;
        },
        
        clearTransactionStatus(state, action) {
            state.isLoading = false;
            state.transactionStatus = null;
        },
    },
});

// Reducer
export default slice.reducer;
// Actions
export const { setSelectedBank, setMethodSelection, setPaymentData, requestTokenSuccess , setBankPageKey, setBankList, clearTransactionStatus} = slice.actions;
// ----------------------------------------------------------------------
export function viewBillingList(userId, isAdmin) {
    return async (dispatch) => {
        dispatch(slice.actions.startLoading());

        try {
            const response = await fetch(
                `${url}Billing_ViewByUser?USERID=${userId}&ISADMIN=${isAdmin}&PROJECTID=${ProjectID}`
            )
            const json = await response.json();
            const data = JSON.parse(json)[0];
            if (data && data.ReturnVal === 1) {
                dispatch(slice.actions.getBillingSuccess(JSON.parse(data.ReturnData)));
            } else {
                dispatch(slice.actions.hasError(JSON.parse(data.ReturnData)[0].ReturnSqlError));
            }
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

// ----------------------------------------------------------------------
export function updateBillingStatus(userId, orderId, isEndorsed, remark) {
    return async (dispatch) => {
        dispatch(slice.actions.startLoading());

        try {
            const response = await fetch(
                `${url}Conference_EndorsedPayment?MODIFIEDBY=${userId}&ORDERID=${orderId}&ISENDORSED=${isEndorsed}&REMARK=${remark}`
            )
            const json = await response.json();
            const data = JSON.parse(json)[0];
            if (data && data.ReturnVal === 1) {
                dispatch(slice.actions.updateBillingSuccess(JSON.parse(data.ReturnData)));
            } else {
                dispatch(slice.actions.hasError(JSON.parse(data.ReturnData)[0].ReturnSqlError));
            }
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

// ----------------------------------------------------------------------
export function unSubscribeBilling(userId, orderId, isEndorsed, remark) {
    return async (dispatch) => {
        dispatch(slice.actions.unSubscribeBillingSuccess());
    };
}

export function checkTokenValidity() {
    return async (dispatch) => {
        dispatch(slice.actions.startLoading());

        try {
            const response = await fetch(
                `${url}General_CheckTokenValidity`
            )
            const json = await response.json();
            const data = JSON.parse(json)[0];
            if (data && data.ReturnVal === 1) {
                dispatch(slice.actions.checkTokenValiditySuccess(JSON.parse(data.ReturnData)));
            } else {
                dispatch(slice.actions.hasError(JSON.parse(data.ReturnData)[0].ReturnSqlError));
            }
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}


export function updateToken(token) {
    return async (dispatch) => {
        dispatch(slice.actions.startLoading());

        try {
            const response = await fetch(
                `${url}General_UpdateTokenValidity?TOKEN=${token}`
            )
            const json = await response.json();
            const data = JSON.parse(json)[0];
            if (data && data.ReturnVal === 1) {
                // dispatch(slice.actions.updateTokenSuccess(JSON.parse(data.ReturnData)));
                dispatch(slice.actions.requestTokenSuccess(token));
            } else {
                dispatch(slice.actions.hasError(JSON.parse(data.ReturnData)[0].ReturnSqlError));
            }
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

export function requestToken(type, clientId, clientSecret) {
    return async (dispatch) => {
        dispatch(slice.actions.startLoading());
        try {
            const response = await axios.post('https://sandbox.api.paynet.my/auth/token', {
                grant_type: type,
                client_id: clientId,
                client_secret: clientSecret
            }, {
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/x-www-form-urlencoded'
                }
            })
            const json = await response;
            if (json.data) {
                dispatch(updateToken(json.data.access_token))
            }
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

export function paynetInitiatePayment(paymentData) {
    
    return async (dispatch) => {
        dispatch(slice.actions.startLoading());
        try {

            const formData = new FormData();
            formData.append("messageId", paymentData.messageId);
            formData.append("transactionId", paymentData.transactionId);
            formData.append("currency", paymentData.currency);
            formData.append("endToEndId", paymentData.endToEndId);
            formData.append("amount", parseFloat(paymentData.amount).toFixed(2));
            formData.append("customer", JSON.stringify({
                "name": paymentData.custname,
                "bankType": paymentData.custbankType
            }));
            formData.append("merchant", JSON.stringify({
                "name": paymentData.merchantName,
                "accountType": paymentData.merchantAccountType
            }));
            formData.append("recipientReference", paymentData.recipientReference);
            formData.append("paymentDescription", paymentData.paymentDescription);
            formData.append("code", paymentData.code);
            formData.append("location", paymentData.location);
            formData.append("userIPAddress", paymentData.userIPAddress);
            formData.append("x_sign", paymentData.x_sign);

            formData.append("sign_key", paymentData.sign_key);
            formData.append("productId", paymentData.productId);
            formData.append("sourceOfFunds", paymentData.sourceOfFunds);

            const config = { headers: { 'Content-Type': 'multipart/form-data' } };            

            const response = axios.post('https://inquirescholars.com/initiatepayment.php', formData, config)
            const json = await response;
            if (json.data) {                
                dispatch(slice.actions.paynetInitiatePaymentSuccess(json.data));

            }
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

export function requestBankList(bankListData) {
    return async (dispatch) => {
        dispatch(slice.actions.startLoading());
        try {
            const formData = new FormData();
            formData.append("data", bankListData.stringtoSign);
            formData.append("messageId", bankListData.messageId);
            formData.append("merchant_id", bankListData.merchant_id);
            formData.append("currentDate", bankListData.currentDate);
            formData.append("token", bankListData.token);
            formData.append("transactionId", bankListData.transactionId);
            formData.append("location", bankListData.location);
            formData.append("userIPAddress", bankListData.userIPAddress);
            formData.append("sign_key", bankListData.sign_key);            
            formData.append("pageKey", bankListData.pageKey);
            const config = { headers: { 'Content-Type': 'multipart/form-data' } };

            // const response2 = axios.post('https://inquirescholars.com/signFieldPK.php', formData, config)
            // const json2 = await response2;
            

            const response = axios.post('https://inquirescholars.com/retrieveBankList.php', formData, config)
            const json = await response;
            if (json.data) {
                // dispatch(slice.actions.setBankPageKey(json.data.pageKey));
                dispatch(slice.actions.requestBankListSuccess(json.data));

            }
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

export function requestTransactionStatus(bankListData) {
    return async (dispatch) => {
        dispatch(slice.actions.startLoading());
        try {
            const formData = new FormData();
            formData.append("data", bankListData.stringtoSign);
            formData.append("messageId", bankListData.messageId);
            formData.append("merchant_id", bankListData.merchant_id);
            formData.append("currentDate", bankListData.currentDate);
            formData.append("token", bankListData.token);
            formData.append("endToEndId", bankListData.endToEndId);
            formData.append("transactionId", bankListData.transactionId);
            formData.append("location", bankListData.location);
            formData.append("userIPAddress", bankListData.userIPAddress);
            formData.append("sign_key", bankListData.sign_key);
            const config = { headers: { 'Content-Type': 'multipart/form-data' } };

            // const response2 = axios.post('https://inquirescholars.com/signFieldPK.php', formData, config)
            // const json2 = await response2;            

            const response = axios.post('https://inquirescholars.com/statusInquiry.php', formData, config)
            const json = await response;
            if (json.data) {
                dispatch(slice.actions.requestTransactionStatusSuccess(json.data
                ));

            }
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

export function requestPaymentStatusNotification(paymentData) {    
    return async (dispatch) => {
        dispatch(slice.actions.startLoading());
        try {
            const formData = new FormData();
            formData.append('paymentData', JSON.stringify(paymentData));

            const config = { headers: { 'Content-Type': 'multipart/form-data' } };           
            const response = axios.post('https://inquirescholars.com/requestPaymentStatusNoti.php', formData, config)
            const json = await response;
            if (json.data) {             
                dispatch(slice.actions.requestPaymentStatusNotificationSuccess(json.data));

            }
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}




