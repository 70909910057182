import PropTypes from 'prop-types';
// @mui
import { List, Stack } from '@mui/material';
// locales
import { useLocales } from '../../../locales';
//
import { StyledSubheader } from './styles';
import NavList from './NavList';

// ----------------------------------------------------------------------

NavSectionVertical.propTypes = {
  sx: PropTypes.object,
  data: PropTypes.array,
};

export default function NavSectionVertical({ data, sx, ...other }) {
  const { translate } = useLocales();

  // return (
  //   <Stack sx={sx} {...other}>
  //     {data?.items.map((group) => {
  //       const key = group.title;

  //       return (
  //         <List key={key} disablePadding sx={{ px: 2 }}>
  //           {/* {group.subheader && (
  //             <StyledSubheader disableSticky>{`${translate(group.subheader)}`}</StyledSubheader>
  //           )} */}

  //           {/* {group.map((list) => ( */}
  //           <NavList
  //             key={group.title + group.path}
  //             data={group}
  //             depth={1}
  //             hasChild={!!group.children}
  //           />
  //           {/* ))} */}
  //         </List>
  //       );
  //     })}
  //   </Stack>
  // );

  return (
    <Stack sx={sx} {...other}>
      {data.map((group) => {
        const key = group.subheader || JSON.parse(group.items)[0].title;

        return (
          <List key={key} disablePadding sx={{ px: 2 }}>
            {group.subheader && (
              <StyledSubheader disableSticky>{translate(group.subheader)}</StyledSubheader>
            )}

            {JSON.parse(group.items).map((list) => (<NavList
                key={list.title + list.path}
                data={list}
                depth={1}
                hasChild={list.ChildrenInd === 1}
              />))}
          </List>
        );
      })}
    </Stack>
  );
}
