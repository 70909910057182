import PropTypes from 'prop-types';
import { memo } from 'react';
// @mui
import { Box, Stack } from '@mui/material';
//
import NavList from './NavList';

// ----------------------------------------------------------------------

NavSectionMini.propTypes = {
  sx: PropTypes.object,
  data: PropTypes.array,
};

function NavSectionMini({ data, sx, ...other }) {
  return (
    <Stack
      spacing={0.5}
      alignItems="center"
      sx={{
        px: 0.75,
        ...sx,
      }}
      {...other}
    >
      {data.map((group, index) => (
        <Items key={group.subheader} items={group.items} isLastGroup={index + 1 === data.length} />
      ))}
    </Stack>
  );
}

export default memo(NavSectionMini);

// ----------------------------------------------------------------------

Items.propTypes = {
  items: PropTypes.array,
  isLastGroup: PropTypes.bool,
};

function Items({ items, isLastGroup }) {
  return (
    <>
      {items.map((list) => (
        <NavList key={list.title + list.path} data={list} depth={1} hasChild={!!list.children} />
      ))}

      {!isLastGroup && (
        <Box
          sx={{
            width: 24,
            height: '1px',
            bgcolor: 'divider',
            my: '8px !important',
          }}
        />
      )}
    </>
  );
}



// import PropTypes from 'prop-types';
// import { memo } from 'react';
// // @mui
// import { List, Stack } from '@mui/material';
// //
// import NavList from './NavList';
// import { useLocales } from '../../../locales';
// import { StyledSubheader } from './styles';
// // ----------------------------------------------------------------------

// NavSectionMini.propTypes = {
//   sx: PropTypes.object,
//   data: PropTypes.array,
// };

// function NavSectionMini({ data, sx, ...other }) {
//   const { translate } = useLocales();
//   return (
//     <Stack
//       spacing={0.5}
//       alignItems="center"
//       sx={{
//         px: 0.75,
//         ...sx,
//       }}
//       {...other}
//     >
//       {/* {data.map((group, index) => (
//         <Items key={group.subheader} items={group.items} isLastGroup={index + 1 === data.length} />
//       ))} */}
//          {data.map((group) => {
//         const key = group.subheader || JSON.parse(group.items)[0].title;

//         return (
//           <List key={key} disablePadding sx={{ px: 2 }}>
//             {group.subheader && (
//               <StyledSubheader disableSticky>{translate(group.subheader)}</StyledSubheader>
//             )}

//             {JSON.parse(group.items).map((list) => (<NavList
//                 key={list.title + list.path}
//                 data={list}
//                 depth={1}
//                 hasChild={list.ChildrenInd === 1}
//               />))}
//           </List>
//         );
//       })}
//     </Stack>
//   );
// }

// export default memo(NavSectionMini);

// // ----------------------------------------------------------------------

// // Items.propTypes = {
// //   items: PropTypes.string,
// //   isLastGroup: PropTypes.bool,
// // };

// // function Items({ items, isLastGroup }) {
// //   const parsedItems = JSON.parse(items);
// //   return (
// //     <>
// //       {parsedItems.map((list) => (
// //         <NavList key={list.title + list.path} data={list} depth={1} hasChild={!!list.children} />
// //       ))}

// //       {!isLastGroup && (
// //         <Box
// //           sx={{
// //             width: 24,
// //             height: '1px',
// //             bgcolor: 'divider',
// //             my: '8px !important',
// //           }}
// //         />
// //       )}
// //     </>
// //   );
// // }
