import { Suspense, lazy } from 'react';
// components
import LoadingScreen from '../components/loading-screen';

// ----------------------------------------------------------------------

const Loadable = (Component) => (props) =>
(
  <Suspense fallback={<LoadingScreen />}>
    <Component {...props} />
  </Suspense>
);

// ----------------------------------------------------------------------

// AUTH
export const LoginPage = Loadable(lazy(() => import('../pages/auth/LoginPage')));
export const RegisterPage = Loadable(lazy(() => import('../pages/auth/RegisterPage')));
export const VerifyCodePage = Loadable(lazy(() => import('../pages/auth/VerifyCodePage')));
export const NewPasswordPage = Loadable(lazy(() => import('../pages/auth/NewPasswordPage')));
export const ResetPasswordPage = Loadable(lazy(() => import('../pages/auth/ResetPasswordPage')));

// LANDING
export const MarketingLandingPage = Loadable(
  lazy(() => import('../pages/landing/MarketingLandingPage'))
);

// Contact us
export const ContactUsPage = Loadable(
  lazy(() => import('../pages/contact-us/ContactUsPage'))
);


export const EcommerceProductDetailsPage = Loadable(
  lazy(() => import('../pages/dashboard/EcommerceProductDetailsPage'))
);
export const EcommerceCheckoutPage = Loadable(
  lazy(() => import('../pages/dashboard/EcommerceCheckoutPage'))
);
export const ConferencePackageCheckoutPage = Loadable(
  lazy(() => import('../pages/dashboard/ConferencePackageCheckoutPage'))
);
export const EcommercePaymentConfirmation = Loadable(
  lazy(() => import('../pages/dashboard/EcommercePaymentConfirmation'))
);

// DASHBOARD: INVOICE
// export const InvoiceListPage = Loadable(lazy(() => import('../pages/dashboard/invoice/InvoiceListPage')));
// export const InvoiceDetailsPage = Loadable(
//   lazy(() => import('../pages/dashboard/invoice/InvoiceDetailsPage'))
// );
export const InvoiceCreatePage = Loadable(
  lazy(() => import('../pages/dashboard/invoice/InvoiceCreatePage'))
);
export const InvoiceEditPage = Loadable(lazy(() => import('../pages/dashboard/invoice/InvoiceEditPage')));

// DASHBOARD: USER
export const UserProfilePage = Loadable(lazy(() => import('../pages/dashboard/userProfile/UserProfilePage')));
export const UserCardsPage = Loadable(lazy(() => import('../pages/dashboard/userProfile/UserCardsPage')));
export const UserListPage = Loadable(lazy(() => import('../pages/dashboard/userProfile/UserListPage')));
export const UserAccountPage = Loadable(lazy(() => import('../pages/dashboard/userProfile/UserAccountPage')));
export const UserCreatePage = Loadable(lazy(() => import('../pages/dashboard/userProfile/UserCreatePage')));
export const UserEditPage = Loadable(lazy(() => import('../pages/dashboard/userProfile/UserEditPage')));
// export const UserCreatePublisherPage = Loadable(lazy(() => import('../pages/dashboard/CreateJournalPage')));
export const UserEditPublisherPage = Loadable(lazy(() => import('../pages/dashboard/publisherListing/EditPublisherPage')));

// AUTHOR SUBMISSION
export const AuthorDashboard = Loadable(lazy(() => import('../pages/dashboard/authorSubmission/GeneralAnalyticsPage')));
export const AuthorJournalList = Loadable(lazy(() => import('../pages/dashboard/authorSubmission/AuthorJournalList')));
export const AuthorManuscriptList = Loadable(lazy(() => import('../pages/dashboard/authorSubmission/AuthorManuscriptList')));
export const AuthorCreateManuscript = Loadable(lazy(() => import('../pages/dashboard/authorSubmission/AuthorCreateManuscript')));
export const JournalDetailsPage = Loadable(lazy(() => import('../pages/dashboard/authorSubmission/JournalDetailsPage')));


// Chief Editor
export const ChiefEditorDashboard = Loadable(lazy(() => import('../pages/dashboard/chiefEditor/GeneralAnalyticsPage')));
export const ChiefEditorViewTaskAssign = Loadable(lazy(() => import('../pages/dashboard/chiefEditor/taskAssignment/ChiefEditorTaskAssignment')));
export const EmailTemplatelisting = Loadable(lazy(() => import('../pages/dashboard/chiefEditor/setting/emailTemplate/emailTemplatelisting')));
export const UserManagement = Loadable(lazy(() => import('../pages/dashboard/chiefEditor/UserManagement/UserListing')));
export const UserDetails = Loadable(lazy(() => import('../pages/dashboard/chiefEditor/UserManagement/UserDetails')));


// REVIEWER
export const ReviewerDashboard = Loadable(lazy(() => import('../pages/dashboard/reviewer/GeneralAppPage')));
export const ReviewerManuscriptList = Loadable(lazy(() => import('../pages/dashboard/reviewer/ReviewerManuscriptList')));
export const ManuscriptDetailsPage = Loadable(lazy(() => import('../pages/dashboard/reviewer/ManuscriptDetailsPage')));
export const ReviewerInvitation = Loadable(lazy(() => import('../pages/dashboard/reviewer/ReviewerInvitation')));
// EDITOR
export const EditorDashboard = Loadable(lazy(() => import('../pages/dashboard/paper/EditorDashboard')));
export const ManuscriptList = Loadable(lazy(() => import('../pages/dashboard/paper/Manuscript/ManuscriptListing')));
export const ConferencePaperDetails = Loadable(lazy(() => import('../pages/dashboard/paper/ConferencePaperSendReviewPage')));
export const EditorInvitation = Loadable(lazy(() => import('../pages/dashboard/paper/EditorInvitation')));

// Conference Management
export const ConferenceEventManagementIndexPage = Loadable(lazy(() => import('../pages/dashboard/event-management/ConferenceEventManagementIndexPage')));
export const ConferenceEventManagementCardViewIndexPage = Loadable(lazy(() => import('../pages/dashboard/event-management/ConferenceEventManagementCardViewIndexPage')));
export const HistoryConferenceEventManagementCardViewIndexPage = Loadable(lazy(() => import('../pages/dashboard/event-management/HistoryConferenceEventManagementCardViewIndexPage')));
export const ConferenceEventManagementForm = Loadable(lazy(() => import('../pages/dashboard/event-management/ConferenceEventManagementForm')));
export const SurveyManagementIndexPage = Loadable(lazy(() => import('../pages/dashboard/survey-management/SurveyMamagementIndexPage')));
export const SurveyForm = Loadable(lazy(() => import('../pages/dashboard/survey-management/SurveyForm')));
export const JournalManagementIndexPage = Loadable(lazy(() => import('../pages/dashboard/journal-management/JournalManagementIndexPage')));
export const SettingManagement = Loadable(lazy(() => import('../pages/dashboard/settings/SettingManagement')));

export const ConferenceShopPage = Loadable(lazy(() => import('../pages/dashboard/event-management/shop/ConferenceShopPage')));
export const ConferenceShopDetailsPage = Loadable(lazy(() => import('../pages/dashboard/event-management/shop/ConferenceShopDetailsPage')));
export const ConferencePaperSendReviewPage = Loadable(lazy(() => import('../pages/dashboard/paper/ConferencePaperSendReviewPage')));

// SURVEY FORM
export const SurveyFormPage = Loadable(lazy(() => import('../pages/SurveyFormPage')));

export const ConferencePaperSubmission = Loadable(lazy(() => import('../pages/dashboard/event-management/paper/ConferenceCreatePaper')));
export const ConferencePaperList = Loadable(lazy(() => import('../pages/dashboard/event-management/paper/ConferencePaperList')));
export const ConferencePaperDetailsPage = Loadable(lazy(() => import('../pages/dashboard/event-management/paper/ConferencePaperDetailsPage')));

// TRANSACTION
export const TransactionListPage = Loadable(lazy(() => import('../pages/dashboard/transaction/TransactionListPage')));
export const TransactionDetailsPage = Loadable(
  lazy(() => import('../pages/dashboard/transaction/TransactionDetailsPage'))
);

// JOURNAL MANAGEMENT (PUBLISHER)
export const JournalsCardDetail = Loadable(lazy(() => import('../pages/dashboard/publisherListing/JournalsCardDetail')));
export const JournalManagementCardViewIndexPage = Loadable(lazy(() => import('../pages/dashboard/journal-management/JournalManagementCardViewIndexPage')));

// ORGANIZATION TRANSACTION
export const OrganizerTransactionDetailsPage = Loadable(
  lazy(() => import('../pages/dashboard/transaction/OrganizerTransactionDetailsPage'))
);

// ADMIN CONFERENCE
export const AdminConferenceListPage = Loadable(lazy(() => import('../pages/dashboard/admin/adminConference/AdminConferenceListPage')));
export const AdminConferenceDetailsPage = Loadable(lazy(() => import('../pages/dashboard/admin/adminConference/AdminConferenceDetailsPage')));
export const AdminConferenceEditPage = Loadable(lazy(() => import('../pages/dashboard/admin/adminConference/AdminConferenceEditPage')));

// ADMIN CONFERENCE
export const AdminTransactionListPage = Loadable(lazy(() => import('../pages/dashboard/admin/adminTransaction/AdminTransactionListPage')));
export const AdminTransactionDetailsPage = Loadable(lazy(() => import('../pages/dashboard/admin/adminTransaction/AdminTransactionDetailsPage')));
export const AdminTransactionEditPage = Loadable(lazy(() => import('../pages/dashboard/admin/adminTransaction/AdminTransactionEditPage')));

// CONFERENCE ASSISTANT INVIATION
export const ConferenceAssistantInvitation = Loadable(lazy(() => import('../pages/dashboard/conferenceListing/ConferenceAssistantInvitation')));
export const JournalAssistantInvitation = Loadable(lazy(() => import('../pages/dashboard/publisherListing/JournalAssistantInvitation')));



