import keyBy from 'lodash/keyBy';
import { createSlice } from '@reduxjs/toolkit';
// utils
import axios from '../../utils/axios';
import { ServerConfiguration, ProjectID } from '../../utils/serverConfig';

// ----------------------------------------------------------------------

const url = ServerConfiguration.testingServerUrl;

const initialState = {
  isLoading: false,
  error: null,
  mails: { byId: {}, allIds: [] },
  mailTemplates: [],
  mailTemplateByID: [],
  returnReviewMailTemplate: [],
  mailDefault: [],
  emailPlaceholder: [],
  labels: [],
  filterMail: [],
  returnReviewAction: null,
};

const slice = createSlice({
  name: 'mail',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    // GET LABELS
    getLabelsSuccess(state, action) {
      state.isLoading = false;
      state.labels = action.payload;
    },

    // GET MAILS
    getMailsSuccess(state, action) {
      const mails = action.payload;
      state.mails.byId = keyBy(mails[0], 'id');
      state.mails.allIds = Object.keys(state.mails.byId);
    },

    // GET MAIL
    getMailSuccess(state, action) {
      const mail = action.payload;

      state.mails.byId[mail.id] = mail;
      if (!state.mails.allIds.includes(mail.id)) {
        state.mails.allIds.push(mail.id);
      }
    },

    getMailsTemplateSuccess(state, action) {
      // const mails = action.payload;
      state.isLoading = false;
      state.mailTemplates = action.payload;
      // state.mails.byId = keyBy(mails[0], 'id');
      // state.mails.allIds = Object.keys(state.mails.byId);
    },

    getMailsTemplateByIDSuccess(state, action) {
      // const mails = action.payload;
      state.isLoading = false;
      state.mailTemplateByID = action.payload;
    },

    getReturnReviewMailsTemplateSuccess(state, action) {
      // const mails = action.payload;
      state.isLoading = false;
      state.returnReviewMailTemplate = action.payload;
    },

    getDefaultMailsTemplateSuccess(state, action) {
      state.isLoading = false;
      state.mailDefault = action.payload;
    },

    editorReturnReviewSuccess(state, action) {
      state.isLoading = false;
      state.returnReviewAction = action.payload;
    },

    getMailByFilter(state, action) {
      state.isLoading = false;
      state.filterMail = action.payload;
    },

    getEmailPlaceHolderOptionsSuccess(state, action) {
      state.isLoading = false;
      state.emailPlaceholder = action.payload;
    },

    // CLEAR RETURN REVIEW ACTION
    clearReturnReviewAction(state) {
      state.returnReviewAction = null;
    },
  },
});

// Reducer
export default slice.reducer;

// Actions
export const {
  clearReturnReviewAction,
} = slice.actions;

// ----------------------------------------------------------------------

export function getLabels() {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get('/api/mail/labels');
      dispatch(slice.actions.getLabelsSuccess(response.data.labels));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function getMailsTemplate() {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await fetch(
        `${url}Paper_EmailTemplate_ViewTemplateByID?ProjectID=${ProjectID}&EMAILTEMPLATEID=${0}&ISEDITTEMPLATE=${1}&PAPERID=${0}&TASKID=${0}`
      )
      const json = await response.json();
      const data = JSON.parse(json);

      if (data[0].ReturnVal === 1) {
        dispatch(slice.actions.getMailsTemplateSuccess(JSON.parse(data[0].ReturnData)));
      }
      else {
        dispatch(slice.actions.hasError(JSON.parse(data[0])));
      }

    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function getMailsTemplateByID(emailTemplateId, paperId, taskId, isEditTemplate) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await fetch(
        `${url}Paper_EmailTemplate_ViewTemplateByID?ProjectID=${ProjectID}&EMAILTEMPLATEID=${emailTemplateId}&PAPERID=${paperId}&TASKID=${taskId}&ISEDITTEMPLATE=${isEditTemplate}`
      )
      const json = await response.json();
      const data = JSON.parse(json);

      if (data[0].ReturnVal === 1) {
        dispatch(slice.actions.getMailsTemplateByIDSuccess(JSON.parse(data[0].ReturnData)));
      }
      else {
        dispatch(slice.actions.hasError(JSON.parse(data[0])));
      }

    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function getReturnReviewMailsTemplate(emailTemplateId, paperId, taskId, isEditTemplate) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await fetch(
        `${url}Paper_EmailTemplate_ViewTemplateByID?ProjectID=${ProjectID}&EMAILTEMPLATEID=${emailTemplateId}&PAPERID=${paperId}&TASKID=${taskId}&ISEDITTEMPLATE=${isEditTemplate}`
      )
      const json = await response.json();
      const data = JSON.parse(json);

      if (data[0].ReturnVal === 1) {
        dispatch(slice.actions.getReturnReviewMailsTemplateSuccess(JSON.parse(data[0].ReturnData)));
      }
      else {
        dispatch(slice.actions.hasError(JSON.parse(data[0])));
      }

    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function getDefaultMailsTemplate() {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await fetch(
        `${url}General_ViewEmailSetting`
      )
      const json = await response.json();
      const data = JSON.parse(json);

      if (data[0].ReturnVal === 1) {
        dispatch(slice.actions.getDefaultMailsTemplateSuccess(JSON.parse(data[0].ReturnData)));
      } else {
        dispatch(slice.actions.hasError(JSON.parse(data[0])));
      }

    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function getEmailPlaceHolderOptions() {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await fetch(
        `${url}General_ViewEmailPlaceholder`
      )
      const json = await response.json();
      const data = JSON.parse(json);

      if (data[0].ReturnVal === 1) {
        dispatch(slice.actions.getEmailPlaceHolderOptionsSuccess(JSON.parse(data[0].ReturnData)));
      }
      else {
        dispatch(slice.actions.hasError(JSON.parse(data[0])));
      }

    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function getMails(params) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get('/api/mail/mails', { params });
      dispatch(slice.actions.getMailsSuccess(response.data.mails));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function getMail(mailId) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get('/api/mail/mail', {
        params: { mailId },
      });
      dispatch(slice.actions.getMailSuccess(response.data.mail));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function EditorReturnReviewPaper(emailType, emailSubject, emailContent, modifyBy, paperId, taskId, isDraft, taskStatus, emailId, isPaperAccepted) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await fetch(
        `${url}Paper_EditorReturnReview?EMAILTYPE=${emailType}&EMAILSUBJECT=${emailSubject}&EMAILCONTENT=${emailContent}&MODIFYBY=${modifyBy}&PAPERID=${paperId}&TASKID=${taskId}&ISDRAFT=${isDraft}&TASKSTATUS=${taskStatus}&EMAILID=${emailId}&ISPAPERACCEPTED=${isPaperAccepted}&ProjectID=${ProjectID}`
      )
      const json = await response.json();
      const data = JSON.parse(json);
      if (data[0].ReturnVal === 1) {
        dispatch(slice.actions.editorReturnReviewSuccess(JSON.parse(data[0].ReturnData)[0]));
      } else {
        dispatch(slice.actions.hasError(JSON.parse(data[0])));
      }

    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function viewEmailByFilter(filterData) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await fetch(
        `${url}Paper_Email_ViewEmailByFilter?FILTERDATA=${filterData}`
      )
      const json = await response.json();
      const data = JSON.parse(json);

      if (data[0].ReturnVal === 1) {
        dispatch(slice.actions.getMailByFilter(JSON.parse(data[0].ReturnData)));
      }
      else {
        dispatch(slice.actions.hasError(JSON.parse(data[0])));
      }

    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}
