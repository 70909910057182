import React from 'react';
// routesa
import { PATH_DASHBOARD, PATH_CONFERENCE_MANAGEMENT } from '../../../routes/paths';
// components
import Label from '../../../components/label';
import Iconify from '../../../components/iconify';
import SvgColor from '../../../components/svg-color';

// ----------------------------------------------------------------------

const icon = (name) => (
  <SvgColor src={`/INQUIRE/assets/icons/navbar/${name}.svg`} sx={{ width: 1, height: 1 }} />
);

const ICONS = {
  blog: icon('ic_blog'),
  cart: icon('ic_cart'),
  chat: icon('ic_chat'),
  mail: icon('ic_mail'),
  user: icon('ic_user'),
  file: icon('ic_file'),
  lock: icon('ic_lock'),
  label: icon('ic_label'),
  blank: icon('ic_blank'),
  kanban: icon('ic_kanban'),
  folder: icon('ic_folder'),
  setting: icon('ic_setting'),
  banking: icon('ic_banking'),
  booking: icon('ic_booking'),
  invoice: icon('ic_invoice'),
  calendar: icon('ic_calendar'),
  disabled: icon('ic_disabled'),
  external: icon('ic_external'),
  menuItem: icon('ic_menu_item'),
  ecommerce: icon('ic_ecommerce'),
  analytics: icon('ic_analytics'),
  dashboard: icon('ic_dashboard'),

  // journal: <Iconify icon="ooui:journal-ltr" />,
  conference: <Iconify icon="carbon:group-presentation" />,
  survey: <Iconify icon="fa6-solid:clipboard-question" />,
  // setting: <Iconify icon="mdi:cog" />,
};

const navConfigListing = [
  // Editor Leader
  // ----------------------------------------------------------------------
  {
    subheader: 'INQUIRE',
    items: [
      { title: 'Join Conference', path: PATH_CONFERENCE_MANAGEMENT.conference.shop, icon: ICONS.conference },
      {
        title: 'History',
        path: '/dashboard/authorSubmission/manuscript',
        icon: ICONS.file,
      },
      // {
      //   title: 'My Journal',
      //   path: PATH_CONFERENCE_MANAGEMENT.journal.listcv,
      //   icon: ICONS.file,
      // },
      {
        title: 'My Conference',
        path: PATH_CONFERENCE_MANAGEMENT.conference.listcv,
        icon: ICONS.file,
      },
      {
        title: 'My Papers',
        path: PATH_DASHBOARD.authorSubmission.manuscript,
        icon: ICONS.file,
      },
      {
        title: 'Invoice',
        path: PATH_DASHBOARD.transaction.root,
        icon: ICONS.invoice,
      },

    ],
  },
  {
    subheader: 'Admin',
    items: [
      {
        title: 'Conference Approval',
        path: PATH_DASHBOARD.admin.conference,
        icon: ICONS.dashboard,
      },
      // {
      //   title: 'Journal Approval',
      //   path: PATH_DASHBOARD.admin.journal,
      //   icon: ICONS.dashboard,
      // },
    ],
  },
  // {
  //   subheader: 'Chief Editor (*)',
  //   items: [
  //     {
  //       title: 'Dashboard',
  //       path: PATH_DASHBOARD.chiefEditor.root,
  //       icon: ICONS.dashboard,
  //     },
  //     {
  //       title: 'Journal Management',
  //       path: PATH_DASHBOARD.user.profile,
  //       icon: ICONS.user,
  //     },
  //     {
  //       title: 'User Management',
  //       path: PATH_DASHBOARD.chiefEditor.usermanagement,
  //       icon: ICONS.user,
  //     },
  //   ],
  // },

  // // REVIEWER
  // // ----------------------------------------------------------------------
  // {
  //   subheader: 'Reviewer',
  //   items: [
  //     {
  //       title: 'Dashboard',
  //       path: PATH_DASHBOARD.reviewer.root,
  //       icon: ICONS.dashboard,
  //     },
  //     {
  //       title: 'Manuscript Review',
  //       path: PATH_DASHBOARD.reviewer.manuscript,
  //       icon: ICONS.file,
  //     },
  //     {
  //       title: 'Setting',
  //       path: PATH_DASHBOARD.mailTemplate.root,
  //       icon: ICONS.setting,
  //       children: [
  //         {
  //           title: 'mail',
  //           path: PATH_DASHBOARD.mailTemplate.list,
  //           icon: ICONS.mail,
  //           // info: <Label color="error">+32</Label>,
  //         },
  //       ],
  //     },
  //   ],
  // },
  // // Editor
  // // ----------------------------------------------------------------------
  // {
  //   subheader: 'Editor Assistant',
  //   items: [
  //     {
  //       title: 'Dashboard',
  //       path: PATH_DASHBOARD.editor.root,
  //       icon: ICONS.dashboard,
  //     },
  //     {
  //       title: 'Manuscript Listing',
  //       path: PATH_DASHBOARD.editor.manuscriptlist,
  //       icon: ICONS.calendar,
  //     },
  //     {
  //       title: 'Setting',
  //       path: PATH_DASHBOARD.mailTemplate.root,
  //       icon: ICONS.setting,
  //       children: [
  //         {
  //           title: 'mail',
  //           path: PATH_DASHBOARD.mailTemplate.list,
  //           icon: ICONS.mail,
  //           // info: <Label color="error">+32</Label>,
  //         },
  //       ],
  //     },
  //   ],
  // },

  // {
  //   subheader: 'Authors',
  //   items: [
  //     // {
  //     //   title: 'Dashboard',
  //     //   path: PATH_DASHBOARD.authorSubmission.root,
  //     //   icon: ICONS.dashboard,
  //     // },
  //     {
  //       title: 'Journal Listing',
  //       path: PATH_DASHBOARD.authorSubmission.journal,
  //       icon: ICONS.blank,
  //     },
  //     {
  //       title: 'Manuscript Listing',
  //       path: PATH_DASHBOARD.authorSubmission.manuscript,
  //       icon: ICONS.file,
  //     },
  //     {
  //       title: 'Setting',
  //       path: PATH_DASHBOARD.mailTemplate.root,
  //       icon: ICONS.setting,
  //       children: [
  //         {
  //           title: 'mail',
  //           path: PATH_DASHBOARD.mailTemplate.list,
  //           icon: ICONS.mail,
  //           // info: <Label color="error">+32</Label>,
  //         },
  //       ],
  //     },
  //   ],
  // },

  // {
  //   subheader: 'Conference Organiser (*)',
  //   items: [
  //     // { title: 'dashboard', path: PATH_CONFERENCE_MANAGEMENT.root, icon: ICONS.dashboard },
  //     {
  //       title: 'My Conference',
  //       path: PATH_CONFERENCE_MANAGEMENT.conference.root,
  //       icon: ICONS.conference,
  //     },

  //     { title: 'Conference paper listing', path: PATH_CONFERENCE_MANAGEMENT.conference.paperlist, icon: ICONS.conference },
  //   ],
  // },
  // {
  //   subheader: 'Survey',
  //   items: [
  //     { title: 'Create Survey', path: PATH_CONFERENCE_MANAGEMENT.survey.root, icon: ICONS.survey },
  //   ],
  // },
  // {
  //   subheader: 'Chat',
  //   items: [
  //     { title: 'Chat', path: PATH_DASHBOARD.chat.root, icon: ICONS.chat },
  //   ],
  // },
];

export default function getNavConfig(selectedUserRoleid) {
  // let accessPage;
  // switch (selectedUserRoleid) {
  //   case 1:
  //     accessPage = navConfigListing.filter((role) => role.subheader === 'Chief Editor' || role.subheader === 'Conference Management')
  //     break;
  //   case 2:
  //     accessPage = navConfigListing.filter((role) => role.subheader === 'Editor' || role.subheader === 'Conference Management')
  //     break;
  //   case 3:
  //     accessPage = navConfigListing.filter((role) => role.subheader === 'Reviewer')
  //     break;
  //   case 4:
  //     accessPage = navConfigListing.filter((role) => role.subheader === 'Authors')
  //     break;
  //   default:
  //     accessPage = navConfigListing.filter((role) => role.subheader === 'Authors')
  //     break;
  // }
  return navConfigListing;
}
