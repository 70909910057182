import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
// @mui
import { alpha } from '@mui/material/styles';
import { Box, Divider, Typography, Stack, MenuItem, ListItemIcon, ListItemText } from '@mui/material';
import { Logout, Settings, AccountBox, LibraryBooks, Event } from '@mui/icons-material';
// routes
import { PATH_DASHBOARD } from '../../../routes/paths';
// auth
import { useAuthContext } from '../../../auth/useAuthContext';
// components
import { CustomAvatar } from '../../../components/custom-avatar';
import { useSnackbar } from '../../../components/snackbar';
import MenuPopover from '../../../components/menu-popover';
import { IconButtonAnimate } from '../../../components/animate';
import getNavConfig from '../nav/config-navigation';
// ----------------------------------------------------------------------

const thirdSection = [
  // {
  //   label: 'Home',
  //   linkTo: '/',
  // },
  {
    label: 'Settings',
    keys: "setting",
    linkTo: PATH_DASHBOARD.user.account,
  }
];
const secondSection = [
  {
    label: 'My Conferences',
    keys: "conference",
    linkTo: PATH_DASHBOARD.user.profile,
  },
]
const firstSection = [

  {
    label: 'Profile',
    keys: "profile",
    linkTo: PATH_DASHBOARD.user.profile,
  },

]
const optionIcons = {
  'Profile': AccountBox,
  'Settings': Settings,
  'My Conferences': Event,
};
// ----------------------------------------------------------------------

export default function AccountPopover() {

  const navigate = useNavigate();

  const { user, logout, setRoleSelected } = useAuthContext();

  const { enqueueSnackbar } = useSnackbar();

  const [openPopover, setOpenPopover] = useState(null);

  const handleOpenPopover = (event) => {
    setOpenPopover(event.currentTarget);
  };

  const handleClosePopover = () => {
    setOpenPopover(null);
  };

  const handleLogout = async () => {
    try {
      logout();
      navigate('/', { replace: true }); // go to main page
      handleClosePopover();
    } catch (error) {
      enqueueSnackbar('Unable to logout!', { variant: 'error' });
    }
  };

  const handleClickItem = (path, pathkey) => {
    handleClosePopover();
    const data = pathkey;
    navigate(path, { state: data });
  };

  // const handleClickRole = async (roleID, role) => {
  //   setRoleSelected(roleID, role);
  //   const path = getNavConfig(roleID)
  //   navigate(path[0]?.items[0].path);
  //   handleClosePopover();
  // };

  return (
    <>
      <IconButtonAnimate
        onClick={handleOpenPopover}
        sx={{
          p: 0,
          ...(openPopover && {
            '&:before': {
              zIndex: 1,
              content: "''",
              width: '100%',
              height: '100%',
              borderRadius: '50%',
              position: 'absolute',
              bgcolor: (theme) => alpha(theme.palette.grey[900], 0.8),
            },
          }),
        }}
      >
        <CustomAvatar src={user?.UserProfileImage} alt={user?.UserFirstName} name={user?.UserFirstName} />
      </IconButtonAnimate>

      <MenuPopover open={openPopover} onClose={handleClosePopover} sx={{ width: 200, p: 0 }}>
        <Box sx={{ my: 1.5, px: 2.5 }}>
          <Typography variant="subtitle2" noWrap>
            {user?.UserFullName}
          </Typography>

          <Typography variant="body2" sx={{ color: 'text.secondary' }} noWrap>
            {user?.UserEmailAddress}
          </Typography>
        </Box>
        <Divider sx={{ borderStyle: 'dashed' }} />
        <Stack sx={{ p: 1 }}>
          {firstSection.map((option) => {
            const IconComponent = optionIcons[option.label];

            return (
              <MenuItem key={option.label} onClick={() => handleClickItem(option.linkTo, option.keys)}>
                {IconComponent && (
                  <IconComponent fontSize="small" />
                )}

                {option.label}
              </MenuItem>
            );
          })}
        </Stack>
        <Divider sx={{ borderStyle: 'dashed' }} />
        <Stack sx={{ p: 1 }}>
          {secondSection.map((option) => {
            const IconComponent = optionIcons[option.label];

            return (
              <MenuItem key={option.label} onClick={() => handleClickItem(option.linkTo, option.keys)}>
                {IconComponent && (
                  <IconComponent fontSize="small" />
                )}

                {option.label}
              </MenuItem>
            );
          })}
        </Stack>


        <Divider sx={{ borderStyle: 'dashed' }} />

        <Stack sx={{ p: 1 }}>
          {thirdSection.map((option) => {
            const IconComponent = optionIcons[option.label];

            return (
              <MenuItem key={option.label} onClick={() => handleClickItem(option.linkTo, option.keys)}>
                {IconComponent && (
                  <IconComponent fontSize="small" />
                )}

                {option.label}
              </MenuItem>
            );
          })}
        </Stack>

        <Divider sx={{ borderStyle: 'dashed' }} />
        {/* 
        <Stack sx={{ p: 1 }}>
          {accessRowtoShow.map((option) => (
            <MenuItem key={option.id} onClick={() => handleClickRole(option.id, option)}>
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  width: '23px',
                  height: '23px',
                  borderRadius: '50%',
                  backgroundColor: `${option?.color}`,
                  color: '#fff',
                  fontSize: '15px',
                  marginRight: '10px' // Add margin for spacing
                }}
              >
                {option?.Name[0]}
              </div>
              {option?.Name}
            </MenuItem>
          ))}
        </Stack> */}

        <Divider sx={{ borderStyle: 'dashed' }} />

        <MenuItem onClick={handleLogout} sx={{ m: 1 }}>
          <Logout fontSize="small" /> Logout
        </MenuItem>
      </MenuPopover >
    </>
  );
}
