import { createSlice } from '@reduxjs/toolkit';
// utils
import axios from '../../utils/axios';
import { ServerConfiguration, ProjectID } from '../../utils/serverConfig';

// ----------------------------------------------------------------------

const url = ServerConfiguration.testingServerUrl;
const PROJECTID = 1;

const initialState = {
  isLoading: false,
  error: null,
  profile: [],
  password: null,
  feedback: null,
};

const slice = createSlice({
  name: 'profile',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    // UPDATE PROFILE
    updateProfileSuccess(state, action) {
      state.isLoading = false;
      state.profile = action.payload;
    },

    // UNSUBSCRIBE UPDATE PROFILE
    unsubscribeUpdateProfileSuccess(state, action) {
      state.isLoading = false;
      state.profile = null;
    },

    // UPDATE PASSWORD
    updatePasswordSuccess(state, action) {
      state.isLoading = false;
      state.password = action.payload;
    },

    // UNSUBSCRIBE UPDATE PASSWORD
    unsubscribeUpdatePasswordSuccess(state, action) {
      state.isLoading = false;
      state.password = null;
    },


    // ADD FEEDBACK
    addFeedbackSucceed(state, action) {
      state.isLoading = false;
      state.feedback = action.payload;
    },

    // UNSUBSCRIBE FEEDBACK
    unsubscribeAddFeedbackSuccess(state, action) {
      state.isLoading = false;
      state.feedback = null;
    },

  },
});

// Reducer
export default slice.reducer;

// ----------------------------------------------------------------------

// export function updateProfile(user, userId, userTypeId, projectId) {
//   return async (dispatch) => {
//     dispatch(slice.actions.startLoading());
//     try {
//       const response = await fetch(
//         `${url}User_Profile_Update?USERID=${userId}&USEREMAIL=${user.email}&PROJECTID=${projectId}&USERCONTACTNO=${user.contactNo}&USERDOB=${user.dob}&USERORCHIDID=${user.orchidId}&USERPREFIX=${user.prefix}&USERFIRSTNAME=${user.firstName}&USERLASTNAME=${user.lastName}&USERINSTITUTION=${user.institution}&USERDEGREE=${user.degree}&USERADDRESS=${user.address}&USERSTATE=${user.state}&USERCOUNTRYID=${user.country}&USERCITY=${user.city}&USERPOSTALCODE=${user.zipCode}&USERRESEARCHID=${user.researchId}&USERGENDER=${user.gender}&USERTYPEID=${userTypeId}&USEREXPERTISE=${user.expertise}&MODIFYBY=${userId}`
//       )
//       const json = await response.json();
//       const data = JSON.parse(json)[0];
//       console.log(data)
//       if (data && data.ReturnVal === 1) {
//         dispatch(slice.actions.updateProfileSuccess(JSON.parse(data.ReturnData)[0]));
//       } else {
//         dispatch(slice.actions.hasError(JSON.parse(data.ReturnData)[0].ReturnSqlError));
//       }
//     } catch (error) {
//       dispatch(slice.actions.hasError(error));
//     }
//   };
// }


export function updateProfile(userId, email, contact, dob, orchidId, userPrefix, firstname, lastname, institution, degree, address, state, countryId, city, poscode, researchId, gender, usertypeId, expertise, userprofileimage, modifyBy) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await fetch(`${url}User_Profile_Update?USERID=${userId}&USEREMAIL=${email}&PROJECTID=${PROJECTID}&USERCONTACTNO=${contact}&USERDOB=${dob}&USERORCHIDID=${orchidId}&USERPREFIX=${userPrefix}&USERFIRSTNAME=${firstname}&USERLASTNAME=${lastname}&USERINSTITUTION=${institution}&USERDEGREE=${degree}&USERADDRESS=${address}&USERCOUNTRYID=${countryId}&USERCITY=${city}&USERSTATE=${state}&USERPOSTALCODE=${poscode}&USERRESEARCHID=${researchId}&USERGENDER=${gender}&USERTYPEID=${usertypeId}&USEREXPERTISE=${expertise}&USERPROFILEIMAGE=${userprofileimage}&MODIFYBY=${modifyBy}`);

      const json = await response.json();
      const data = JSON.parse(json);
      if (data[0].ReturnVal === 1) {
        dispatch(slice.actions.updateProfileSuccess(JSON.parse(data[0].ReturnData)));
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function unsubscribeUpdateProfile() {
  return async (dispatch) => {
    dispatch(slice.actions.unsubscribeUpdateProfileSuccess());
  };
}

// ----------------------------------------------------------------------

export function updatePassword(passwords, userId) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await fetch(
        `${url}User_Profile_Update_Password?USERID=${userId}&OLDPASSWORD=${passwords.oldPassword}&NEWPASSWORD=${passwords.newPassword}`
      );
      const json = await response.json();
      const data = JSON.parse(json)[0];
      if (data && data.ReturnVal === 1) {
        dispatch(slice.actions.updatePasswordSuccess(JSON.parse(data.ReturnData)[0]));
      }
      else {
        // dispatch(slice.actions.hasError(JSON.parse(data.ReturnData)[0].ReturnSqlError));
        dispatch(slice.actions.hasError(data.ReturnSqlError));
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}


// ----------------------------------------------------------------------
// Feedback_AddFeedback(string SUBJECT, string BODY, string EMAIL, string NAME, string PROJECTID)
export function addFeedback({ SUBJECT, BODY, EMAIL, NAME }) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await fetch(
        `${url}Feedback_AddFeedback?SUBJECT=${SUBJECT}&BODY=${BODY}&EMAIL=${EMAIL}&NAME=${NAME}&PROJECTID=${ProjectID}`
      );
      const json = await response.json();
      const data = JSON.parse(json)[0];
      if (data && data.ReturnVal === 1) {
        dispatch(slice.actions.addFeedbackSucceed(JSON.parse(data.ReturnData)[0]));
      }
      else {
        // dispatch(slice.actions.hasError(JSON.parse(data.ReturnData)[0].ReturnSqlError));
        dispatch(slice.actions.hasError(data.ReturnSqlError));
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function unsubscribeUpdatePassword() {
  return async (dispatch) => {
    dispatch(slice.actions.unsubscribeUpdatePasswordSuccess());
  };
}

export function unsubscribeAddFeedback() {
  return async (dispatch) => {
    dispatch(slice.actions.unsubscribeAddFeedbackSuccess());
  };
}