// routes
import { PATH_BASE,PATH_AUTH, PATH_DOCS, PATH_PAGE,PATH_DASHBOARD } from '../../../routes/paths';
// config
import { PATH_AFTER_LOGIN } from '../../../config-global';
// components
import Iconify from '../../../components/iconify';

// ----------------------------------------------------------------------

export const pageLinks = [
  {
    order: '1',
    subheader: 'Inquire',
    cover: '/assets/images/menu/menu_marketing.jpg',
    items: [
      { title: 'About us', path: PATH_BASE.root },
      { title: 'Service', path: PATH_BASE.root },
      { title: 'Team', path: PATH_BASE.root },
      // { title: 'Pricing', path: PATH_DASHBOARD.landing.landing },
      { title: 'FAQ', path: PATH_BASE.root },
      { title: 'Contact us', path: PATH_PAGE.contact },
    ],
  },
  // {
  //   order: '6',
  //   subheader: 'Travel',
  //   cover: '/assets/images/menu/menu_travel.jpg',
  //   items: [
  //     { title: 'Landing', path: PATH_DASHBOARD.landing.landing },
  //     { title: 'Services', path: PATH_DASHBOARD.landing.landing },
  //     { title: 'Case Studies', path: PATH_DASHBOARD.landing.landing },
  //     { title: 'Case Study', path: PATH_DASHBOARD.landing.landing },
  //     { title: 'Blog Post', path: PATH_DASHBOARD.landing.plandingost },
  //     { title: 'About', path: PATH_DASHBOARD.landing.landing },
  //     { title: 'Contact', path: PATH_DASHBOARD.landing.landing },
  //   ],
  // },
  // {
  //   order: '2',
  //   subheader: 'Career',
  //   cover: '/assets/images/menu/menu_career.jpg',
  //   items: [
  //     { title: 'Landing', path: PATH_DASHBOARD.landing.landing },
  //     { title: 'Services', path: PATH_DASHBOARD.landing.landing },
  //     { title: 'Case Studies', path: PATH_DASHBOARD.landing.landing },
  //     { title: 'Blog Post', path: PATH_DASHBOARD.landing.landing },
  //     { title: 'About', path: PATH_DASHBOARD.landing.landing },
  //     { title: 'Contact', path: PATH_DASHBOARD.landing.landing },
  //   ],
  // },
  {
    order: '5',
    subheader: 'Term',
    cover: '/assets/images/menu/menu_elearning.jpg',
    items: [
      { title: 'General Term and Condition', path: PATH_DASHBOARD.landing.landing },
      { title: 'Policies and Information', path: PATH_DASHBOARD.landing.landing },
      { title: 'Privacy', path: PATH_DASHBOARD.landing.landing },
      { title: 'Author Term and Condition', path: PATH_DASHBOARD.landing.landing },
      { title: 'Reviewer Term and Condition', path: PATH_DASHBOARD.landing.landing },
    ],
  }
];


const navConfig = [
  {
    title: 'Home',
    icon: <Iconify icon="eva:home-fill" />,
    path: '/',
  },
  {
    title: 'About us',
    icon: <Iconify icon="ic:round-grain" />,
    path: "#aboutus",
  },
  {
    title: 'Our Service',
    icon: <Iconify icon="ic:round-grain" />,
    path: "#ourservice",
  },
  {
    title: 'Team',
    icon: <Iconify icon="ic:round-grain" />,
    path: "#team",
  },
  {
    title: 'Faq',
    icon: <Iconify icon="ic:round-grain" />,
    path: "#faq",
  },
];

export default navConfig;
