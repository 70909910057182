import { Navigate, useRoutes } from 'react-router-dom';
// auth
import AuthGuard from '../auth/AuthGuard';
import GuestGuard from '../auth/GuestGuard';
// layouts
import MainLayout from '../layouts/main';
import CompactLayout from '../layouts/compact';
import DashboardLayout from '../layouts/dashboard';
// config
import { PATH_AFTER_LOGIN } from '../config-global';
//
import {
  // Auth
  LoginPage,
  RegisterPage,
  VerifyCodePage,
  NewPasswordPage,
  ResetPasswordPage,
  UserListPage,
  UserEditPage,
  UserCardsPage,
  UserCreatePage,
  UserProfilePage,
  UserAccountPage,
  UserEditPublisherPage,
  EcommerceCheckoutPage,
  ConferencePackageCheckoutPage,
  EcommerceProductDetailsPage,
  // InvoiceListPage,
  // InvoiceDetailsPage,
  InvoiceCreatePage,
  InvoiceEditPage,
  AuthorDashboard,
  AuthorJournalList,
  AuthorManuscriptList,
  AuthorCreateManuscript,
  JournalDetailsPage,
  // EDITOR
  EditorDashboard,
  ManuscriptList,
  ConferencePaperDetails,
  EditorInvitation,
  ChiefEditorDashboard,
  ChiefEditorViewTaskAssign,
  UserManagement,
  UserDetails,
  // REVIEWER
  ReviewerDashboard,
  ReviewerManuscriptList,
  ManuscriptDetailsPage,
  ReviewerInvitation,
  // Conference Management
  ConferenceEventManagementIndexPage,
  ConferenceEventManagementCardViewIndexPage,
  HistoryConferenceEventManagementCardViewIndexPage,
  ConferenceEventManagementForm,
  JournalManagementIndexPage,
  SurveyManagementIndexPage,
  SurveyForm,
  SettingManagement,
  ConferenceShopPage,
  ConferencePaperSubmission,
  ConferencePaperList,
  ConferencePaperDetailsPage,
  ConferenceShopDetailsPage,
  ConferencePaperSendReviewPage,
  // JOURNALS/Publisher Management
  JournalsCardDetail,
  JournalManagementCardViewIndexPage,
  // Landing
  MarketingLandingPage,
  // SURVEY FORM
  SurveyFormPage,
  // TRANSACTION
  TransactionListPage,
  TransactionDetailsPage,
  // ORGANIZER TRANSACTION
  OrganizerTransactionDetailsPage,
  // ADMIN TRANSACTION
  AdminConferenceListPage,
  AdminConferenceDetailsPage,
  AdminConferenceEditPage,
  AdminTransactionListPage,
  AdminTransactionDetailsPage,
  AdminTransactionEditPage,
  ConferenceAssistantInvitation,
  JournalAssistantInvitation,

  EcommercePaymentConfirmation,
  // Contact us
  ContactUsPage

} from './elements';

// ----------------------------------------------------------------------

export default function Router() {
  return useRoutes([
    // Main Routes
    {
      path: '/',
      element: <MainLayout />,
      children: [
        { path: '', element: <MarketingLandingPage /> },
        { path: 'contact-us', element: <ContactUsPage /> },
      ],
    },
    // Auth
    {
      path: 'auth',
      children: [
        {
          path: 'login',
          element: (
            <GuestGuard>
              <LoginPage />
            </GuestGuard>
          ),
        },
        {
          path: 'register/:useremail/:usertypeId/:auth',
          element: (
            <GuestGuard>
              <RegisterPage />
            </GuestGuard>
          ),
        },
        { path: 'reviewer/invitation/:email/:paperid', element: <ReviewerInvitation /> },
        { path: 'editor/invitation/:email', element: <EditorInvitation /> },
        { path: 'login-unprotected', element: <LoginPage /> },
        {
          path: '',
          element: <CompactLayout />,
          children: [
            { path: 'reset-password', element: <ResetPasswordPage /> },
            { path: 'new-password', element: <NewPasswordPage /> },
            { path: 'verify', element: <VerifyCodePage /> },
          ],
        },
      ],
    },

    // Dashboard
    {
      path: 'dashboard',
      element: (
        <AuthGuard>
          <DashboardLayout />
        </AuthGuard>
      ),
      children: [
        { element: <Navigate to={PATH_AFTER_LOGIN} replace />, index: true },
        {
          path: 'admin',
          children: [
            { element: <Navigate to="/dashboard/admin/conference" replace />, index: true },
            { path: 'conference', element: <AdminConferenceListPage /> },
            { path: 'conference/:id', element: <AdminConferenceDetailsPage /> },
            { path: 'conference/:id/edit', element: <AdminConferenceEditPage /> },
            { path: 'transaction', element: <AdminTransactionListPage /> },
            { path: 'transaction/:id', element: <AdminTransactionDetailsPage /> },
            { path: 'transaction/:id/edit', element: <AdminTransactionEditPage /> },
          ],
        },

        {
          path: 'e-commerce',
          children: [
            { element: <Navigate to="/dashboard/e-commerce/shop" replace />, index: true },
            { path: 'confirmation/:id/paperid', element: <EcommerceCheckoutPage /> },
            { path: 'product/:id', element: <EcommerceProductDetailsPage /> },
            { path: 'checkout', element: <EcommerceCheckoutPage /> },
          ],
        },
        {
          path: 'user',
          children: [
            { element: <Navigate to="/dashboard/user/profile" replace />, index: true },
            { path: 'profile', element: <UserProfilePage /> },
            { path: 'cards', element: <UserCardsPage /> },
            { path: 'list', element: <UserListPage /> },
            { path: 'new', element: <UserCreatePage /> },
            { path: ':id/edit', element: <UserEditPage /> },
            { path: 'account', element: <UserAccountPage /> },
            { path: 'editpublisher/:id', element: <UserEditPublisherPage /> },
          ],
        },
        {
          path: 'invoice',
          children: [
            { element: <Navigate to="/dashboard/invoice/list" replace />, index: true },
            { path: 'list/:id/edit', element: <InvoiceEditPage /> },
            { path: 'list/new', element: <InvoiceCreatePage /> },
          ],
        },
        {
          path: 'authorSubmission',
          children: [
            { element: <Navigate to="/dashboard/authorSubmission/manuscript" replace />, index: true },
            { path: 'dashboard', element: <AuthorDashboard /> },
            { path: 'manuscript', element: <AuthorManuscriptList /> },
            { path: 'manuscript/:id', element: <JournalDetailsPage /> },
            { path: 'journal', element: <AuthorJournalList /> },
            { path: 'manuscript/new', element: <AuthorCreateManuscript /> },
            { path: 'manuscript/:id/edit', element: <AuthorCreateManuscript /> },
          ],
        },
        {
          path: 'chiefEditor',
          children: [
            { element: <UserManagement />, index: true },
            { path: 'dashboard', element: <ChiefEditorDashboard /> },
            { path: 'taskAssign', element: <ChiefEditorViewTaskAssign /> },
            { path: 'usermanagement', element: <UserManagement /> },
            { path: 'userdetails/:userid', element: <UserDetails /> },
          ],
        },
        {
          path: 'reviewer',
          children: [
            { element: <Navigate to="/dashboard/reviewer/manuscript" replace />, index: true },
            { path: 'app', element: <ReviewerDashboard /> },
            { path: 'manuscript', element: <ReviewerManuscriptList /> },
            { path: 'manuscript/:id', element: <ManuscriptDetailsPage /> },
            { path: 'invitation/:email/:paperid', element: <ReviewerInvitation /> },
          ],
        },
        {
          path: 'editor',
          children: [
            { element: <Navigate to="/dashboard/editor/ManuscriptListing" replace />, index: true },
            { path: 'dashboard', element: <EditorDashboard /> },
            { path: 'ManuscriptListing', element: <ManuscriptList /> },
            { path: 'ConferencePaperDetails/:taskid/:paperid', element: <ConferencePaperDetails /> },
            { path: 'invitation/:email', element: <EditorInvitation /> },
          ],
        },
        {
          path: 'transaction',
          children: [
            { element: <Navigate to="/dashboard/transaction/list" replace />, index: true },
            { path: 'list', element: <TransactionListPage /> },
            { path: 'list/:id', element: <TransactionDetailsPage /> },
          ],
        },
        {
          path: 'userPublisherConferenceListing',
          children: [
            { element: <Navigate to="/dashboard/userPublisherConferenceListing/" replace />, index: true },
            { path: 'view/:id', element: <JournalsCardDetail /> },
          ],
        },
      ],
    },
    {
      path: '/RPP/MY/Redirect/RTP',
      element: <EcommercePaymentConfirmation />,
    },
    {

      path: 'conference', // the main path to the journal management, type /DJMS will redirect to the index page
      element: (
        <AuthGuard>
          <DashboardLayout />
        </AuthGuard>
      ),
      children: [
        { element: <ConferenceEventManagementIndexPage />, index: true },
        {
          path: 'conference-event-management',
          children: [
            { element: <Navigate to="/conference/conference-event-management/conference-event" replace />, index: true },
            { path: 'conference-event', element: <ConferenceEventManagementIndexPage /> },
            { path: 'conference-cv-event', element: <ConferenceEventManagementCardViewIndexPage /> },
            { path: 'history', element: <HistoryConferenceEventManagementCardViewIndexPage /> },
            { path: 'history/:id', element: <ConferenceShopDetailsPage page="detail" /> },
            { path: 'history/:id/:taskId/:paperId', element: <ConferencePaperSendReviewPage /> },
            { path: 'list', element: <ConferenceEventManagementIndexPage /> },
            { path: 'confirmation/:id/:paperid', element: <EcommerceCheckoutPage /> },
            { path: 'conferenceMembership/:id/', element: <ConferencePackageCheckoutPage /> },
            { path: 'form/:id', element: <ConferenceEventManagementForm /> },
            { path: 'shop', element: <ConferenceShopPage /> },
            { path: 'paperlist', element: <ConferencePaperList /> },
            { path: 'paperSubmission/:id/edit', element: <ConferencePaperSubmission /> },
            { path: 'paper/:id', element: <ConferencePaperDetailsPage /> },
            { path: 'shop/:id', element: <ConferenceShopDetailsPage page="shop" /> },
            { path: 'detail/:id', element: <ConferenceShopDetailsPage page="detail" /> },
            { path: 'shop/:id/:participantId', element: <OrganizerTransactionDetailsPage /> },
          ],
        },
        {
          path: 'journal-management',
          children: [
            { element: <Navigate to="/conference/journal-management/journal" replace />, index: true },
            { path: 'journal-cv-event', element: <JournalManagementCardViewIndexPage /> },
            { path: 'journal', element: <JournalManagementIndexPage /> },
          ],
        },
        {
          path: 'survey-management',
          children: [
            { element: <Navigate to="/conference/survey-management/survey" replace />, index: true },
            { path: 'survey', element: <SurveyManagementIndexPage /> },
            { path: 'form', element: <SurveyForm /> },
            { path: 'form/:id', element: <SurveyForm /> },
            { path: 'form/:id/edit', element: <SurveyForm /> },
          ],
        },
        {
          path: 'settings',
          children: [
            { element: <Navigate to="/conference/settings/general-setting" replace />, index: true },
            { path: 'general-setting', element: <SettingManagement /> },
          ],
        },
      ],
    },
    {
      path: 'survey-form/:id',
      element: <SurveyFormPage />,
    },
    {
      path: 'conference-details',
      element: <MainLayout />,
      children: [
        { path: ':id', element: <ConferenceShopDetailsPage page="invitation" /> },
      ],
    },
    {
      path: 'conference-assistant-invitation/:email/:id/:roleId/:hostId',
      element: <ConferenceAssistantInvitation />,
    },
    {
      path: 'journal-assistant-invitation/:email/:id/:roleId/:hostId',
      element: <JournalAssistantInvitation />,
    },
  ]);
}
