import { createSlice } from '@reduxjs/toolkit';
// utils
import { ProjectID, ServerConfiguration } from '../../utils/serverConfig';

// ----------------------------------------------------------------------

const url = ServerConfiguration.testingServerUrl;

// const projectId = 1;

const initialState = {
  isLoading: false,
  error: null,
  conferencePaper: null,
  updatedPaperStatus: null,
  paper: null,
};

const slice = createSlice({
  name: 'conference-paper',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    // BACK STEP
    backStep(state) {
      state.submission.activeStep -= 1;
    },

    // NEXT STEP
    nextStep(state) {
      state.submission.activeStep += 1;
    },

    // RESET STEP
    resetStep(state) {
      state.submission.activeStep = 0;
    },

    // GO TO STEP
    goToStep(state, action) {
      state.submission.activeStep = action.payload;
    },

    // UPDATE FILE UPLOAD
    getPaperByConferenceIdSuccess(state, action) {
      state.isLoading = false;
      state.conferencePaper = action.payload;
    },

    // UPDATE PAPER STATUS SUCCESS
    updatePaperStatusSuccess(state, action) {
      state.isLoading = false;
      state.updatedPaperStatus = action.payload;
    },

    // GET PAPER BY ID SUCCESS
    getPaperByIdSuccess(state, action) {
      state.isLoading = false;
      state.paper = action.payload;
    },
  },
});

// Reducer
export default slice.reducer;

// Actions
export const {
  backStep,
  nextStep,
  resetStep,
  goToStep,
  updatePaperIntro,
  updateFileUpload,
  updateAttributes,
  updateAuthorInstitution,
  updateDetailComment,
  unsubscrbeAddManuscriptSuccess,
  unsubscribeUpdateJournalSuccess,
  resetFiles,
  resetState,
} = slice.actions;

// ----------------------------------------------------------------------

export function getPaperByConferenceId(conferenceId, project, conferencePaperId, userID) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await fetch(
        `${url}Paper_AuthorPaper_PaperListByConferenceID?CONFERENCEID=${conferenceId}&CONFERENCEPAPERID=${conferencePaperId}&PROJECTID=${project}&USERID=${userID}`
      )
      const json = await response.json();
      const data = JSON.parse(json)[0];
      if (data && data.ReturnVal === 1) {
        dispatch(slice.actions.getPaperByConferenceIdSuccess(JSON.parse(data.ReturnData)));
      } else {
        dispatch(slice.actions.hasError(JSON.parse(data.ReturnData)[0].ReturnSqlError));
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function updatePaperStatus(paperId, status, userId, subject, content) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await fetch(
        `${url}Paper_UpdatePaperStatus?PAPERID=${paperId}&PAPERSTATUSID=${status}&MODIFYBY=${userId}&PROJECTID=${ProjectID}&EMAILSUBJECT=${subject}&EMAILCONTENT=${content}`
      )
      const json = await response.json();
      const data = JSON.parse(json)[0];
      if (data && data.ReturnVal === 1) {
        dispatch(slice.actions.updatePaperStatusSuccess({
          data: JSON.parse(data.ReturnData)[0],
          message: data.ReturnMsg
        }));
      } else {
        dispatch(slice.actions.hasError(JSON.parse(data.ReturnData)[0].ReturnSqlError));
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function getPaperById(paperId) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await fetch(
        `${url}Paper_AuthorPaper_PaperListByPaperID?PAPERID=${paperId}&PROJECTID=${ProjectID}`
      )
      const json = await response.json();
      const data = JSON.parse(json)[0];
      if (data && data.ReturnVal === 1) {
        dispatch(slice.actions.getPaperByIdSuccess(JSON.parse(data.ReturnData)[0]));
      } else {
        dispatch(slice.actions.hasError(JSON.parse(data.ReturnData)[0].ReturnSqlError));
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}