import { createSlice } from '@reduxjs/toolkit';
// utils
import { ServerConfiguration,ProjectID } from '../../utils/serverConfig';

// ----------------------------------------------------------------------

const url = ServerConfiguration.testingServerUrl;

const initialState = {
  isLoading: false,
  error: null,
  surveys: [],
  newSurvey: null,
  updatedSurvey: null,
  deletedSurvey: null,
};

const slice = createSlice({
  name: 'survey',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    // GET SURVEYS
    getSurveysSuccess(state, action) {
      state.isLoading = false;
      state.surveys = action.payload;
    },

    // ADD SURVEY
    addSurveySuccess(state, action) {
      state.isLoading = false;
      state.newSurvey = action.payload;
    },

    // UPDATE SURVEY
    updateSurveySuccess(state, action) {
      state.isLoading = false;
      state.updatedSurvey = action.payload;
    },

    // DELETE SURVEY
    deleteSurveySuccess(state, action) {
      state.isLoading = false;
      state.deletedSurvey = action.payload;
    },

    // UNSUBSCRIBE SURVEY ACTION
    resetSurveyAction(state, action) {
      state.newSurvey = null;
      state.updatedSurvey = null;
      state.deletedSurvey = null;
    },

    // UNSUBSCRIBE ERROR
    unsubscribeError(state, action) {
      state.error = null;
    },
  },
});

// Reducer
export default slice.reducer;

// Actions
export const {
  resetSurveyAction,
  unsubscribeError,
} = slice.actions;

// ----------------------------------------------------------------------

export function getSurveys(id) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await fetch(
        `${url}Survey_ViewSurvey?SURVEYID=${id}`
      )
      const json = await response.json();
      const data = JSON.parse(json)[0];
      if (data && data.ReturnVal === 1) {
        dispatch(slice.actions.getSurveysSuccess(JSON.parse(data.ReturnData)));
      } else {
        dispatch(slice.actions.hasError(JSON.parse(data.ReturnData).ReturnSqlError));
      }
    } catch (error) {
      console.log(error)
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function addSurvey(name, description, question, options, answerTypeId, required, createdBy) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await fetch(
        `${url}Survey_AddSurvey?SURVEYNAME=${name}&SURVEYDESCRIPTION=${description}&SURVEYQUESTION=${question}&SURVEYQUESTIONDETAIL=${options}&SURVEYQUESTIONREQUIREDIND=${required}&ANSWERTYPEID=${answerTypeId}&PROJECTID=${ProjectID}&CREATEDBY=${createdBy}`
      )
      const json = await response.json();
      const data = JSON.parse(json)[0];
      if (data && data.ReturnVal === 1) {
        dispatch(slice.actions.addSurveySuccess(JSON.parse(data.ReturnData)));
      } else {
        dispatch(slice.actions.hasError(JSON.parse(data.ReturnData).ReturnSqlError));
      }
    } catch (error) {
      console.log(error)
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function updateSurvey(surveyId, name, description, questionOrder, question, required, optionOrder, option, answerTypeId, modifiedBy) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await fetch(
        `${url}Survey_UpdateSurvey?SURVEYID=${surveyId}&SURVEYNAME=${name}&SURVEYDESCRIPTION=${description}&SURVEYQUESTIONNO=${questionOrder}&SURVEYQUESTION=${question}&SURVEYQUESTIONREQUIREDIND=${required}&SURVEYQUESTIONDETAILNO=${optionOrder}&SURVEYQUESTIONDETAIL=${option}&ANSWERTYPEID=${answerTypeId}&PROJECTID=${ProjectID}&MODIFIEDBY=${modifiedBy}`
      )
      const json = await response.json();
      const data = JSON.parse(json)[0];
      if (data && data.ReturnVal === 1) {
        dispatch(slice.actions.updateSurveySuccess(JSON.parse(data.ReturnData)));
      } else {
        dispatch(slice.actions.hasError(JSON.parse(data.ReturnData).ReturnSqlError));
      }
    } catch (error) {
      console.log(error)
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function deleteSurvey(surveyId, modifiedBy) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await fetch(
        `${url}Survey_DeleteSurvey?SURVEYID=${surveyId}&MODIFIEDBY=${modifiedBy}`
      )
      const json = await response.json();
      const data = JSON.parse(json)[0];
      if (data && data.ReturnVal === 1) {
        dispatch(slice.actions.deleteSurveySuccess(JSON.parse(data.ReturnData)[0]));
      } else {
        dispatch(slice.actions.hasError(JSON.parse(data.ReturnData).ReturnSqlError));
      }
    } catch (error) {
      console.log(error)
      dispatch(slice.actions.hasError(error));
    }
  };
}