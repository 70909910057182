import PropTypes from 'prop-types';
// @mui
import { Stack, Button } from '@mui/material';
//
import NavList from './NavList';

// ----------------------------------------------------------------------

NavDesktop.propTypes = {
  data: PropTypes.array,
  isOffset: PropTypes.bool,
};

export default function NavDesktop({ isOffset, data }) {
  const handleClickScroll = (path) => {
    const element = document.getElementById(path);
    if (element) {
      // 👇 Will scroll smoothly to the top of the next section
      element.scrollIntoView({ behavior: 'smooth' });
    }
  };

  return (
    <Stack component="nav" direction="row" spacing={5} sx={{ mr: 5, height: 1, color: '#B6D0E2' }}>
      {data.map((link) => (
        <Button
          key={link.title}
          onClick={() => handleClickScroll(link.path)}
        >
          <NavList key={link.title} item={link} isOffset={isOffset} />
        </Button>
      ))}
    </Stack>
  );
}
