import PropTypes from 'prop-types';
import '../../utils/highlight';
import ReactQuill, { Quill } from 'react-quill';
import { useEffect, useRef } from 'react';
//
import { StyledEditor } from './styles';
import EditorToolbar, { formats } from './EditorToolbar';
// ----------------------------------------------------------------------

const BlockEmbed = Quill.import('blots/block/embed');
const Inline = Quill.import('blots/inline');

class CustomButton extends BlockEmbed {

  static blotName = 'button';

  static tagName = 'button';

  // static create(value) {
  //   const node = super.create(value);
  //   const button = document.createElement("button");
  //   button.innerText = value;
  //   node.appendChild(button);
  //   return node;
  // }

  static create(value) {
    const node = super.create(value);
    try {
      // const button = document.createElement("button");
      // button.innerText = value;
      // button.setAttribute('disabled', 'true');
      // button.setAttribute('contenteditable', false);
      // node.appendChild(button);


    } catch (error) {
      console.error(error);
    }
    return node;
  }

  static value(node) {
    return node.outerHTML;
  }
}

Quill.register(CustomButton);

Editor.propTypes = {
  id: PropTypes.string,
  sx: PropTypes.object,
  error: PropTypes.bool,
  simple: PropTypes.bool,
  value: PropTypes.string,
  onChange: PropTypes.func,
  helperText: PropTypes.object,
  disabled: PropTypes.bool,
};

export default function Editor({
  id = 'minimal-quill',
  error,
  value,
  onChange,
  simple = false,
  helperText,
  sx,
  disabled,
  ...other
}) {
  const modules = {
    toolbar: {
      container: `#${id}`,
    },
    history: {
      delay: 500,
      maxStack: 100,
      userOnly: true,
    },
    syntax: true,
    clipboard: {
      matchVisual: false,
    },
  };

  const handleDrop = (event) => {
    const data = event.dataTransfer.getData('text/html');
    const parser = new DOMParser();
    if (data.startsWith('<button')) {
      const quill = quillRef.current.getEditor();
      const doc = parser.parseFromString(data, "text/html");
      const button = doc.querySelector("button");
      if (button) {
        const range = quill.getSelection();
        const buttonClone = button.cloneNode(true);
        quill.insertEmbed(range, "button", buttonClone);
        // quill.insertEmbed(range, "button", button.outerHTML);
        // quill.insertText(range, button.outerHTML);

      }
    }
  };

  const quillRef = useRef(null);

  // useEffect(() => {
  //   const quill = quillRef.current.getEditor();
  //   quill.root.addEventListener('drop', handleDrop);
  //   return () => {
  //     quill.root.removeEventListener('drop', handleDrop);
  //   };
  // }, []);

  useEffect(() => {
    const quill = quillRef.current.getEditor();
    
    if (disabled) {
      quill.disable();
    } else {
      quill.enable();
    }
    
    quill.root.addEventListener('drop', handleDrop);
    
    return () => {
      quill.root.removeEventListener('drop', handleDrop);
    };
  }, [disabled]);

  
  return (
    <>
      <StyledEditor
        sx={{
          ...(error && {
            border: (theme) => `solid 1px ${theme.palette.error.main}`,
          }),
          ...sx,
        }}
      >
        <EditorToolbar id={id} isSimple={simple} />

        <ReactQuill
          ref={quillRef}
          value={value}
          onChange={onChange}
          modules={modules}
          formats={formats}
          placeholder="Write something ..."
          disabled={disabled}
          {...other}
        />
      </StyledEditor>

      {helperText && helperText}
    </>
  );
}
