// ----------------------------------------------------------------------

function path(root, sublink) {
  return `${root}${sublink}`;
}

const ROOTS_AUTH = '/auth';
const ROOTS_BASE = '/INQUIRE';
const ROOTS_DASHBOARD = '/dashboard';
const ROOTS_CONFERENCE = '/conference';

// ----------------------------------------------------------------------

export const PATH_AUTH = {
  root: ROOTS_AUTH,
  login: path(ROOTS_AUTH, '/login'),
  loginUnprotected: path(ROOTS_AUTH, '/login-unprotected'),
  // registerUnprotected: path(ROOTS_AUTH, '/register-unprotected'),
  verify: path(ROOTS_AUTH, '/verify'),
  resetPassword: path(ROOTS_AUTH, '/reset-password'),
  newPassword: path(ROOTS_AUTH, '/new-password'),
  register: (useremail, usertypeId, auth) => path(ROOTS_AUTH, `/register/${useremail}/${usertypeId}/${auth}`),
  reviewerInvitation: (email, paperid) => path(ROOTS_AUTH, `/reviewer/invitation/${email}/${paperid}`),
  editorInvitation: (email) => path(ROOTS_AUTH, `/editor/invitation/${email}`),
};

export const PATH_BASE = {
  root:  ROOTS_BASE,
}

export const PATH_PAGE = {
  comingSoon: '/coming-soon',
  surveyForm: (id) => `/survey-form/${id}`,
  maintenance: '/maintenance',
  pricing: '/pricing',
  payment: '/payment',
  about: '/about-us',
  contact: '/contact-us',
  faqs: '/faqs',
  page403: '/403',
  page404: '/404',
  page500: '/500',
  components: '/components',
  paymentConfirmation: '/RPP/MY/Redirect/RTP',
};

export const PATH_DASHBOARD = {
  root: ROOTS_DASHBOARD,
  kanban: path(ROOTS_DASHBOARD, '/kanban'),
  calendar: path(ROOTS_DASHBOARD, '/calendar'),
  fileManager: path(ROOTS_DASHBOARD, '/files-manager'),
  permissionDenied: path(ROOTS_DASHBOARD, '/permission-denied'),
  blank: path(ROOTS_DASHBOARD, '/blank'),
  landing: {
    landing: path(ROOTS_DASHBOARD, '/landing'),
    services: path(ROOTS_DASHBOARD, '/services'),
    caseStudies: path(ROOTS_DASHBOARD, '/case-studies'),
    caseStudy: path(ROOTS_DASHBOARD, '/case-study'),
    posts: path(ROOTS_DASHBOARD, '/posts'),
    post: path(ROOTS_DASHBOARD, '/post'),
    about: path(ROOTS_DASHBOARD, '/about'),
    // contact: path(ROOTS_DASHBOARD, '/contact'),
  },
  general: {
    app: path(ROOTS_DASHBOARD, '/app'),
    ecommerce: path(ROOTS_DASHBOARD, '/ecommerce'),
    analytics: path(ROOTS_DASHBOARD, '/analytics'),
    banking: path(ROOTS_DASHBOARD, '/banking'),
    booking: path(ROOTS_DASHBOARD, '/booking'),
    file: path(ROOTS_DASHBOARD, '/file'),
  },
  chat: {
    root: path(ROOTS_DASHBOARD, '/chat'),
    new: path(ROOTS_DASHBOARD, '/chat/new'),
    view: (name) => path(ROOTS_DASHBOARD, `/chat/${name}`),
  },
  user: {
    root: path(ROOTS_DASHBOARD, '/user'),
    new: path(ROOTS_DASHBOARD, '/user/new'),
    list: path(ROOTS_DASHBOARD, '/user/list'),
    cards: path(ROOTS_DASHBOARD, '/user/cards'),
    profile: path(ROOTS_DASHBOARD, '/user/profile'),
    account: path(ROOTS_DASHBOARD, '/user/account'),
    createpublisher: (id) => path(ROOTS_DASHBOARD, `/user/createpublisher/${id}`),
    // createpublisher: path(ROOTS_DASHBOARD, '/user/createpublisher'),
    editpublisher: (id) => path(ROOTS_DASHBOARD, `/user/editpublisher/${id}`),
    // createjournal: path(ROOTS_DASHBOARD, '/user/createjournal'),
    // edit: (id) => path(ROOTS_DASHBOARD, `/user/${id}/edit`),
    demoEdit: path(ROOTS_DASHBOARD, `/user/reece-chung/edit`),
  },
  tasks: {
    root: path(ROOTS_DASHBOARD, '/tasks'),
    new: path(ROOTS_DASHBOARD, '/tasks/new'),
    list: path(ROOTS_DASHBOARD, '/tasks/list'),
    cards: path(ROOTS_DASHBOARD, '/tasks/cards'),
    profile: path(ROOTS_DASHBOARD, '/tasks/profile'),
    account: path(ROOTS_DASHBOARD, '/tasks/account'),
    edit: (id) => path(ROOTS_DASHBOARD, `/tasks/${id}/edit`),
  },
  eCommerce: {
    root: path(ROOTS_DASHBOARD, '/e-commerce'),
    shop: path(ROOTS_DASHBOARD, '/e-commerce/shop'),
    list: path(ROOTS_DASHBOARD, '/e-commerce/list'),
    // checkout:  (id,paperId) => path( ROOTS_DASHBOARD, `/e-commerce/checkout/${id}/${paperId}`),

    confirmation: (id, paperid) => path(ROOTS_DASHBOARD, `/e-commerce/confirmation/${id}/${paperid}`),
    checkout: path(ROOTS_DASHBOARD, `/e-commerce/checkout`),
    new: path(ROOTS_DASHBOARD, '/e-commerce/product/new'),
    view: (id) => path(ROOTS_DASHBOARD, `/e-commerce/product/${id}`),
    edit: (name) => path(ROOTS_DASHBOARD, `/e-commerce/product/${name}/edit`),
    demoEdit: path(ROOTS_DASHBOARD, '/e-commerce/product/nike-blazer-low-77-vintage/edit'),
    demoView: path(ROOTS_DASHBOARD, '/e-commerce/product/nike-air-force-1-ndestrukt'),
  },
  admin: {
    root: path(ROOTS_DASHBOARD, '/admin'),
    conference: path(ROOTS_DASHBOARD, '/admin/conference'),
    viewConference: (id) => path(ROOTS_DASHBOARD, `/admin/conference/${id}`),
    editConference: (id) => path(ROOTS_DASHBOARD, `/admin/conference/${id}/edit`),
    transaction: path(ROOTS_DASHBOARD, '/admin/transaction'),
    viewTransaction: (id) => path(ROOTS_DASHBOARD, `/admin/transaction/${id}`),
    editTransaction: (id) => path(ROOTS_DASHBOARD, `/admin/transaction/${id}/edit`),
  },
  invoice: {
    root: path(ROOTS_DASHBOARD, '/invoice'),
    list: path(ROOTS_DASHBOARD, '/invoice/list'),
    new: path(ROOTS_DASHBOARD, '/invoice/list/new'),
    view: (id) => path(ROOTS_DASHBOARD, `/invoice/list/${id}`),
    edit: (id) => path(ROOTS_DASHBOARD, `/invoice/list/${id}/edit`),
    demoEdit: path(ROOTS_DASHBOARD, '/invoice/e99f09a7-dd88-49d5-b1c8-1daf80c2d7b1/edit'),
    demoView: path(ROOTS_DASHBOARD, '/invoice/e99f09a7-dd88-49d5-b1c8-1daf80c2d7b5'),
  },
  blog: {
    root: path(ROOTS_DASHBOARD, '/blog'),
    posts: path(ROOTS_DASHBOARD, '/blog/posts'),
    new: path(ROOTS_DASHBOARD, '/blog/new'),
    view: (title) => path(ROOTS_DASHBOARD, `/blog/post/${title}`),
    demoView: path(ROOTS_DASHBOARD, '/blog/post/apply-these-7-secret-techniques-to-improve-event'),
  },
  leaveApplication: {
    root: path(ROOTS_DASHBOARD, '/leaveApplication'),
    new: path(ROOTS_DASHBOARD, '/leaveApplication/leave/new'),
    view: (id) => path(ROOTS_DASHBOARD, `/leaveApplication/leave/${id}`),
    edit: (id) => path(ROOTS_DASHBOARD, `/leaveApplication/leave/${id}/edit`),
  },
  authorSubmission: {
    root: path(ROOTS_DASHBOARD, '/authorSubmission/dashboard'),
    journal: path(ROOTS_DASHBOARD, '/authorSubmission/journal'),
    manuscript: path(ROOTS_DASHBOARD, '/authorSubmission/manuscript'),
    new: path(ROOTS_DASHBOARD, '/authorSubmission/manuscript/new'),
    view: (id) => path(ROOTS_DASHBOARD, `/authorSubmission/manuscript/${id}`),
    edit: (id) => path(ROOTS_DASHBOARD, `/authorSubmission/manuscript/${id}/edit`),
  },
  chiefEditor: {
    root: path(ROOTS_DASHBOARD, '/chiefEditor/dashboard'),
    taskAssign: path(ROOTS_DASHBOARD, '/chiefEditor/taskAssign'),
    usermanagement: path(ROOTS_DASHBOARD, '/chiefEditor/usermanagement'),
    userdetails: (userid) => path(ROOTS_DASHBOARD, `/chiefEditor/userdetails/${userid}`),
    view: path(ROOTS_DASHBOARD, `/chiefEditor/listing`),

    edit: (id) => path(ROOTS_DASHBOARD, `/chiefEditor/leave/${id}/edit`),
  },
  mailTemplate: {
    root: path(ROOTS_DASHBOARD, '/mailTemplate'),
    list: path(ROOTS_DASHBOARD, '/mailTemplate/list'),
    edit: (id) => path(ROOTS_DASHBOARD, `/mailTemplate/${id}/edit`),
  },
  reviewer: {
    root: path(ROOTS_DASHBOARD, '/reviewer/app'),
    manuscript: path(ROOTS_DASHBOARD, '/reviewer/manuscript'),
    details: (id) => path(ROOTS_DASHBOARD, `/reviewer/manuscript/${id}`),
    invitation: (email, paperid) => path(ROOTS_DASHBOARD, `/reviewer/invitation/${email}/${paperid}`),
  },
  editor: {
    root: path(ROOTS_DASHBOARD, '/editor/dashboard'),
    manuscriptlist: path(ROOTS_DASHBOARD, '/editor/ManuscriptListing'),
    manuscriptdetail: (taskid, paperid) => path(ROOTS_DASHBOARD, `/editor/ConferencePaperDetails/${taskid}/${paperid}`),
    invitation: (email) => path(ROOTS_DASHBOARD, `/editor/invitation/${email}`),
    // details: (id) => path(ROOTS_DASHBOARD, `/editor/ConferencePaperDetails/${id}`),
    // new: path(ROOTS_DASHBOARD, '/authorSubmission/journal/new'),
    // view: (id) => path(ROOTS_DASHBOARD, `/leaveApplication/leave/${id}`),
    // edit: (id) => path(ROOTS_DASHBOARD, `/leaveApplication/leave/${id}/edit`),
  },
  // transaction
  transaction: {
    root: path(ROOTS_DASHBOARD, '/transaction'),
    list: path(ROOTS_DASHBOARD, '/transaction/list'),
    new: path(ROOTS_DASHBOARD, '/transaction/list/new'),
    view: (id) => path(ROOTS_DASHBOARD, `/transaction/list/${id}`),
    edit: (id) => path(ROOTS_DASHBOARD, `/transaction/list/${id}/edit`),
  },
  // userPublisherConferenceManagement
  userPublisherConferenceListing: {
    // root: path(ROOTS_DASHBOARD, '/userPublisherConferenceListing'),
    // list: path(ROOTS_DASHBOARD, '/transaction/list'),
    // new: path(ROOTS_DASHBOARD, '/transaction/list/new'),
    view: (id) => path(ROOTS_DASHBOARD, `/userPublisherConferenceListing/view/${id}`),
    // edit: (id) => path(ROOTS_DASHBOARD, `/transaction/list/${id}/edit`),
  },
};

export const PATH_CONFERENCE_MANAGEMENT = {
  root: ROOTS_CONFERENCE,
  conference: {
    // to be removed
    root: path(ROOTS_CONFERENCE, '/conference-event-management/conference-event'),
    confirmation: (id, paperid) => path(ROOTS_CONFERENCE, `/conference-event-management/confirmation/${id}/${paperid}`),
    conferenceMembership: (id) => path(ROOTS_CONFERENCE, `/conference-event-management/conferenceMembership/${id}`),
    listcv: path(ROOTS_CONFERENCE, '/conference-event-management/conference-cv-event'),
    list: path(ROOTS_CONFERENCE, '/conference-event-management/list'),
    shop: path(ROOTS_CONFERENCE, '/conference-event-management/shop'),
    form: (id) => path(ROOTS_CONFERENCE, `/conference-event-management/form/${id}`),
    paperSubmission: (id) => path(ROOTS_CONFERENCE, `/conference-event-management/paperSubmission/${id}/edit`),
    editSubmission: (id) => path(ROOTS_CONFERENCE, `/conference-event-management/paper/${id}/editSubmission`),
    paperlist: path(ROOTS_CONFERENCE, `/conference-event-management/paperlist`),
    paperDetails: (id) => path(ROOTS_CONFERENCE, `/conference-event-management/paper/${id}`),
    detail: (id) => path(ROOTS_CONFERENCE, `/conference-event-management/detail/${id}`),
    view: (id) => path(ROOTS_CONFERENCE, `/conference-event-management/shop/${id}`),
    viewTransaction: (id, participantId) => path(ROOTS_CONFERENCE, `/conference-event-management/shop/${id}/${participantId}`),
    transaction: path(ROOTS_CONFERENCE, '/conference-event-management/transaction'),
    newTransaction: path(ROOTS_CONFERENCE, '/conference-event-management/transaction/new'),
    // viewTransaction: (id) => path(ROOTS_CONFERENCE, `/conference-event-management/transaction/${id}`),
    editTransaction: (id) => path(ROOTS_CONFERENCE, `/conference-event-management/transaction/${id}/edit`),
    historyDetail: (id) => path(ROOTS_CONFERENCE, `/conference-event-management/history/${id}`),
    sendReview: (id, taskId, paperId) => path(ROOTS_CONFERENCE, `/conference-event-management/history/${id}/${taskId}/${paperId}`),
  },
  journal: {
    root: path(ROOTS_CONFERENCE, '/journal-management'),
    listcv: path(ROOTS_CONFERENCE, '/journal-management/journal-cv-event'),
  },
  survey: {
    root: path(ROOTS_CONFERENCE, '/survey-management'),
    form: path(ROOTS_CONFERENCE, '/survey-management/form'),
    view: (id) => path(ROOTS_CONFERENCE, `/survey-management/form/${id}`),
    edit: (id) => path(ROOTS_CONFERENCE, `/survey-management/form/${id}/edit`),
  },
  setting: {
    root: path(ROOTS_CONFERENCE, '/settings'),
  },
};

export const PATH_DOCS = {
  root: 'https://docs.minimals.cc',
  changelog: 'https://docs.minimals.cc/changelog',
};

export const PATH_ZONE_ON_STORE = 'https://mui.com/store/items/zone-landing-page/';

export const PATH_MINIMAL_ON_STORE = 'https://mui.com/store/items/minimal-dashboard/';

export const PATH_FREE_VERSION = 'https://mui.com/store/items/minimal-dashboard-free/';

export const PATH_FIGMA_PREVIEW =
  'https://www.figma.com/file/rWMDOkMZYw2VpTdNuBBCvN/%5BPreview%5D-Minimal-Web.26.11.22?node-id=0%3A1&t=ya2mDFiuhTXXLLF1-1';
