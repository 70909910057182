import PropTypes from 'prop-types';
import { forwardRef } from 'react';
import { Link as RouterLink } from 'react-router-dom';
// @mui
import { useTheme } from '@mui/material/styles';
import { Box, Link } from '@mui/material';
// assets
import logoImage from '../../assets/logo/logo.png';

// ----------------------------------------------------------------------

const Logo = forwardRef(({ disabledLink = false, sx, ...other }, ref) => {
  const theme = useTheme();

  const PRIMARY_LIGHT = theme.palette.primary.light;

  const PRIMARY_MAIN = theme.palette.primary.main;

  const PRIMARY_DARK = theme.palette.primary.dark;

  const logo = (
    <Box
      component="img"
      src={logoImage}
      sx={{ width: 40, height: 40, cursor: 'pointer', ...sx }}
    />
  );

  if (disabledLink) {
    return logo;
  }

  return (
    <Link component={RouterLink} to="/" sx={{ display: 'contents' }}>
      {logo}
    </Link>
  );
});

Logo.propTypes = {
  sx: PropTypes.object,
  disabledLink: PropTypes.bool,
};

export default Logo;

// import PropTypes from 'prop-types';
// import { forwardRef } from 'react';
// import { Link as RouterLink } from 'react-router-dom';
// // @mui
// import { useTheme } from '@mui/material/styles';
// import { Box, Link } from '@mui/material';
// // assets
// import logoImage from '../../assets/logo/logo.png';

// // ----------------------------------------------------------------------

// const Logo = forwardRef(({ disabledLink = false, sx, ...other }, ref) => {
//   const theme = useTheme();

//   const PRIMARY_LIGHT = theme.palette.primary.light;

//   const PRIMARY_MAIN = theme.palette.primary.main;

//   const PRIMARY_DARK = theme.palette.primary.dark;

//   const logoWidth = 40; // Original width of the logo image
//   const logoHeight = 40; // Original height of the logo image

//   const calculatedHeight = (logoHeight / logoWidth) * sx.maxWidth;

//   const logo = (
//     <Box
//       component="img"
//       src={logoImage}
//       sx={{ width: '100%', height: calculatedHeight, cursor: 'pointer', ...sx }}
//     />
//   );

//   if (disabledLink) {
//     return logo;
//   }

//   return (
//     <Link component={RouterLink} to="/" sx={{ display: 'contents' }}>
//       {logo}
//     </Link>
//   );
// });

// Logo.propTypes = {
//   sx: PropTypes.object,
//   disabledLink: PropTypes.bool,
// };

// export default Logo;
